import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReUsable from "../components/ReUsable";
import RelatedBlog from "../components/RelatedBlog";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  serverTimestamp,
  Timestamp,
  updateDoc,
  orderBy,
  where,
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock
} from "@fortawesome/fontawesome-free-solid";
import { db } from "../Firebase";

const Blogdetail = ({ handleModalShow }) => {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [blogs, setBlogs] = useState([]);
  const [tags, setTags] = useState([]);
  const [relatedBlogs, setRelatedBlogs] = useState([]);

  const navigate = useNavigate();



  const getBlogDetail = async () => {
    const blogRef = collection(db, "blogs");
    const blogQuery = query(blogRef, where("slug", "==", slug), limit(1));
    const blogSnapshot = await getDocs(blogQuery);
    if (blogSnapshot.docs.length === 0) {
      return navigate("/404");
    }
    const blogDoc = blogSnapshot.docs[0];
    const blogDetail = blogDoc.data();
    let tags = [];
    const blogs = await getDocs(blogRef);
    blogs.docs.map((doc) => tags.push(...doc.get("tags")));
    let uniqueTags = [...new Set(tags)];
    setTags(uniqueTags);
    setBlog({ slug: blogDoc.id, ...blogDetail });
    const relatedBlogsQuery = query(
      blogRef,
      where("tags", "array-contains-any", blogDetail.tags, limit(3))
    );
    const relatedBlogSnapshot = await getDocs(relatedBlogsQuery);
    const relatedBlogs = [];
    relatedBlogSnapshot.forEach((doc) => {
      relatedBlogs.push({ slug: doc.id, ...doc.data() });
    });
    setRelatedBlogs(relatedBlogs);
  };

  const getBlogs = async () => {
    const blogRef = collection(db, "blogs");
    const firstFour = query(blogRef, orderBy("title"), limit(4));
    const docSnapshot = await getDocs(firstFour);
    setBlogs(docSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  };

  useEffect(() => {
    const getRecentBlogs = async () => {
      const blogRef = collection(db, "blogs");
      const recentBlogs = query(blogRef, orderBy("timestamp", "desc"), limit(5));
      const docSnapshot = await getDocs(recentBlogs);
      setBlogs(docSnapshot.docs.map((doc) => ({ slug: doc.id, ...doc.data() })));
    };
    getRecentBlogs();
  }, []);

  useEffect(() => {
    slug && getBlogDetail();
  }, [slug]);

  useEffect(() => {
    getBlogs();
  }, []);

  if (blog?.slug !== blog?.slug) {
    return navigate("/404");
  }


  return (
    <>
      <Helmet>
        <title>{blog !== null ? (blog?.metaTitle) : ("Blog Detail")}</title>
        <meta name="description" content={blog !== null ? (blog?.metaDesc) : ("blog Detail Desciption")} />
      </Helmet>
      <div className="blog-banner">
        <div className="container">
          <div className="row">
            <div className="col content text-center">
              <h1 className="fs-3">
                <b>
                  {blog?.title}
                </b>
              </h1>
            </div>
          </div>
        </div>
      </div>

      {/* post content section start  */}
      <div className="post-content py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="single-post">
                <div className="imgbx">
                  <img
                    className="img-fluid"
                    src={blog?.imgUrl}
                    alt={blog?.title}
                  />
                </div>
                <div className="content mt-2">
                  <div className="border-bottom pb-2">
                    <small><FontAwesomeIcon icon={faClock} /> {blog?.timestamp}</small>
                  </div>
                  <h5 className="post-title my-3 fs-4">
                    {blog?.title}
                  </h5>
                  <p class="blog_detail__desc">
                  <div dangerouslySetInnerHTML={{ __html: blog?.description }} />
                  </p>
                  <p className="text-secondary mb-3 font-weight-bold mt-3">Tags: {""}</p>
                  <p className="mb-5">
                    {
                      blog?.tags.map((item, i) => (
                        <button key={i} className="btn text-white border border-0 rounded-0 me-2" style={{ background: "#19C5C2" }}>{item}</button>
                      ))
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 sidebar">
              <div className="sidebar-wrap">

                <RelatedBlog slug={slug} blogs={relatedBlogs} />

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* post content section end  */}

      <ReUsable handleModalShow={handleModalShow} />
    </>
  );
};

export default Blogdetail;
