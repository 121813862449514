import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    updateProfile,
} from "firebase/auth";
import { useState } from "react";
import { auth } from "../../Firebase";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
};

const Auth = ({ setUser }) => {
    const [state, setState] = useState(initialState);
    const [signUp, setSignUp] = useState(false);

    const { email, password, firstName, lastName, confirmPassword } = state;

    const navigate = useNavigate();

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const handleAuth = async (e) => {
        e.preventDefault();
        if (!signUp) {
            if (email && password) {
                try {
                    const { user } = await signInWithEmailAndPassword(auth, email, password);
                    if (user) {
                        navigate("/blog-crud");
                        toast.success("Thanks for Login");
                        setUser(user);
                    }
                } catch (error) {
                    const errorCode = error.code;
                    if (errorCode === "auth/user-not-found" || errorCode === "auth/wrong-password") {
                        toast.error("Invalid email or password");
                    }
                }
            } else {
                return toast.error("All fields are mandatory to fill");
            }
        } else {
            if (password !== confirmPassword) {
                return toast.error("Password don't match");
            }
            if (firstName && lastName && email && password) {
                const { user } = await createUserWithEmailAndPassword(
                    auth,
                    email,
                    password
                );
                await updateProfile(user, { displayName: `${firstName} ${lastName}` });
                toast.info("you are signup", { displayName: `${firstName} ${lastName}` });
            } else {
                return toast.error("All fields are mandatory to fill");
            }
        }
    };

    return (
        <>
            <div className="authentication my-5">
                <div className="container">
                    <div className="card-wrapper">
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <div className="card border border-0 shadow">
                                    <div className="card-body">
                                        {/* <!-- Logo --> */}
                                        <div className="app-brand justify-content-center">
                                            <a href="index.html" className="app-brand-link gap-2">
                                                <span className="app-brand-logo demo">
                                                    {/* logo paste here  */}
                                                </span>
                                            </a>
                                        </div>
                                        {/* <!-- /Logo --> */}
                                        <h4 className="mb-3 mt-2 text-center">{!signUp ? "Sign In" : "Sign Up"}</h4>

                                        <form id="formAuthentication" className="mb-3" onSubmit={handleAuth}>
                                            {signUp && (
                                                <>
                                                    <div className="row">
                                                        <div className="mb-3 col">
                                                            <label htmlFor="lastName" className="form-label">First Name</label>
                                                            <input type="text" className="form-control shadow-none" id="firstName" name="firstName" value={firstName} onChange={handleChange} />
                                                        </div>
                                                        <div className="mb-3 col">
                                                            <label htmlFor="lastName" className="form-label">Last Name</label>
                                                            <input type="text" className="form-control shadow-none" id="lastName" name="lastName" value={lastName} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            <div className="mb-3">
                                                <label htmlFor="email" className="form-label">Email</label>
                                                <input type="text" className="form-control shadow-none" id="email" name="email" value={email} onChange={handleChange} />
                                            </div>
                                            <div className="mb-3 form-password-toggle">
                                                <div className="d-flex justify-content-between">
                                                    <label className="form-label" htmlFor="password">Password</label>
                                                </div>
                                                <div className="input-group input-group-merge">
                                                    <input type="password" id="password" className="form-control shadow-none" name="password" value={password} onChange={handleChange} />
                                                </div>
                                            </div>
                                            {signUp && (
                                                <>
                                                    <div className="mb-3 form-password-toggle">
                                                        <div className="d-flex justify-content-between">
                                                            <label className="form-label" htmlFor="confirmPassword">Confirm Password</label>
                                                        </div>
                                                        <div className="input-group input-group-merge">
                                                            <input type="password" id="confirmPassword shadow-none" className="form-control" name="confirmPassword" value={confirmPassword} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            <div className="mb-3">
                                                <button className="btn btn-primary d-grid w-100" type="submit">{!signUp ? "Sign In" : "Sign Up"}</button>
                                            </div>
                                        </form>
                                        {/* {!signUp ? (
                        <>
                            <p className="text-center">
                                <span>Don't have an account ?&nbsp;</span>
                                <NavLink to="#">
                                    <span onClick={() => setSignUp(true)} className="text-danger">Sign Up</span>
                                </NavLink>
                            </p>
                        </>
                    ) : (
                        <>
                            <p className="text-center">
                                <span>Already have an account ?&nbsp;</span>
                                <NavLink to="#">
                                    <span onClick={() => setSignUp(false)} className="text-danger">Sign In</span>
                                </NavLink>
                            </p>
                        </>
                    )} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Auth;
