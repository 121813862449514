import { useState, useEffect } from "react";
import { db } from "../../Firebase";
import { useNavigate, NavLink, useParams } from "react-router-dom";
import {
  addDoc,
  collection,
  getDoc,
  serverTimestamp,
  doc,
  updateDoc,
  deleteDoc,
  getDocs,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const initialState = {
  HomeMetaTitle: "",
  HomeMetaDesc: "",
  AboutMetaTitle: "",
  AboutMetaDesc: "",
  ContactMetaTitle: "",
  ContactMetaDesc: "",
  BlogMetaTitle: "",
  BlogMetaDesc: "",
  WebDevelopmentMetaTitle: "",
  WebDevelopmentMetaDesc: "",
};

const Metas = () => {
  const [form, setForm] = useState(initialState);
  const [meta, setMeta] = useState([]);

  const { id } = useParams();

  const navigate = useNavigate();

  const { HomeMetaTitle, HomeMetaDesc, AboutMetaTitle, AboutMetaDesc, ContactMetaTitle, ContactMetaDesc, BlogMetaTitle, BlogMetaDesc, WebDevelopmentMetaTitle, WebDevelopmentMetaDesc } = form;

  useEffect(() => {
    id && getBlogDetail();
  }, [id]);

  const getBlogDetail = async () => {
    const docRef = doc(db, "metaData", id);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      setForm({ ...snapshot.data() });
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  const fetchMetas = async () => {
    const BlogColl = collection(db, "metaData");
    const final = await getDocs(BlogColl).then((querySnapshot) => {
      const newData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      setMeta(newData);
    })
  }

  useEffect(() => {
    fetchMetas();
  }, [])


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (HomeMetaTitle && HomeMetaDesc && AboutMetaTitle && AboutMetaDesc, ContactMetaTitle && ContactMetaDesc, BlogMetaTitle && BlogMetaDesc && WebDevelopmentMetaTitle && WebDevelopmentMetaDesc) {
      if (!id) {
        try {
          await addDoc(collection(db, "metaData"), {
            ...form,
            timestamp: serverTimestamp(),
          });
          toast.success("Blog created successfully");
          navigate("/meta-datas");
          fetchMetas();
        } catch (err) {
          console.log(err);
        }
      } else {
        try {
          await updateDoc(doc(db, "metaData", id), {
            ...form,
            timestamp: serverTimestamp(),
          });
          toast.success("Blog updated successfully");
          navigate("/meta-datas");
          fetchMetas();
        } catch (err) {
          console.log(err);
        }
      }
    } else {
      return toast.error("All fields are mandatory to fill");
    }

    navigate("/meta-datas");
  };

  // delete data from firestore 
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure wanted to delete that Metas ?")) {
      try {
        await deleteDoc(doc(db, "metaData", id));
        toast.success("Blog deleted successfully");
        fetchMetas();
        navigate("/meta-datas");
      } catch (err) {
        toast.error(err);
      }
    }
  };

  return (
   <>
     <Helmet>
        <title>Pages Meta Title and Meta Description</title>
        <meta name="description" content="Pages Meta Title and Meta Description" />
      </Helmet>
    <div className="addeditblog my-5">
      <div className="container">
        <div className="card">
          <div className="card-body">
            {/* table start  */}
            {
              meta.length === 0 ? (
                <p className="text-center">No Data Found</p>
              ) : (
                <>
                  <h2 className="my-3 fs-4">Meta Title & Description</h2>
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="table-secondary">
                        <tr>
                          <th scope="col">Page Name</th>
                          <th colSpan="2" scope="col">Title</th>
                          <th colSpan="2" scope="col">Description</th>
                        </tr>
                      </thead>
                      {meta.map((t, index) => (
                        <tbody key={index}>
                          <tr>
                            <th scope="col">Home Metas</th>
                            <td colSpan="2">{t.HomeMetaTitle}</td>
                            <td colSpan="2">{t.HomeMetaDesc}</td>
                          </tr>
                          <tr>
                            <th scope="col">About Metas</th>
                            <td colSpan="2">{t.AboutMetaTitle}</td>
                            <td colSpan="2">{t.AboutMetaDesc}</td>
                          </tr>
                          <tr>
                            <th scope="col">Contact Metas</th>
                            <td colSpan="2">{t.ContactMetaTitle}</td>
                            <td colSpan="2">{t.ContactMetaDesc}</td>
                          </tr>
                          <tr>
                            <th scope="col">Web Dev Metas</th>
                            <td colSpan="2">{t.WebDevelopmentMetaTitle}</td>
                            <td colSpan="2">{t.WebDevelopmentMetaDesc}</td>
                          </tr>
                          <tr>
                            <th scope="col">Blog Metas</th>
                            <td colSpan="2">{t.BlogMetaTitle}</td>
                            <td colSpan="2">{t.BlogMetaDesc}</td>
                          </tr>
                          <tr >
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><button
                              className="btn btn-success btn-sm"
                            >
                              <NavLink className="text-white" to={`/meta-data/${t.id}`}> Edit</NavLink>
                            </button>
                            </td>
                            <td>
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() => handleDelete(t.id)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                </>
              )
            }
            {/* table end */}

            {/* form start  */}
            {
              meta.length === 0 || id ? (
                <form className="blog-form" onSubmit={handleSubmit}>
                  <h6 className="fs-5 my-3">{id ? "Update" : "Add"} Meta Title & Description</h6>
                  <div className="row mb-3">
                    <h6 className="mb-3 fs-6">Home:</h6>
                    <div className="form-group col">
                      <label htmlFor="HomeMetaTitle" className="form-label">Meta Title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Meta Title"
                        name="HomeMetaTitle"
                        value={form.HomeMetaTitle}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group col">
                      <label htmlFor="HomeMetaDesc" className="form-label">Meta Description</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Meta Description"
                        name="HomeMetaDesc"
                        value={form.HomeMetaDesc}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <h6 className="mb-3 fs-6">About:</h6>
                    <div className="form-group col">
                      <label htmlFor="AboutMetaTitle" className="form-label">Meta Title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Meta Title"
                        name="AboutMetaTitle"
                        value={form.AboutMetaTitle}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group col">
                      <label htmlFor="AboutMetaDesc" className="form-label">Meta Description</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Meta Description"
                        name="AboutMetaDesc"
                        value={form.AboutMetaDesc}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <h6 className="mb-3 fs-6">Contact:</h6>
                    <div className="form-group col">
                      <label htmlFor="ContactMetaTitle" className="form-label">Meta Title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Meta Title"
                        name="ContactMetaTitle"
                        value={form.ContactMetaTitle}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group col">
                      <label htmlFor="ContactMetaDesc" className="form-label">Meta Description</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Meta Description"
                        name="ContactMetaDesc"
                        value={form.ContactMetaDesc}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <h6 className="mb-3 fs-6">Web Development:</h6>
                    <div className="form-group col">
                      <label htmlFor="WebDevelopmentMetaTitle" className="form-label">Meta Title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Meta Title"
                        name="WebDevelopmentMetaTitle"
                        value={form.WebDevelopmentMetaTitle}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group col">
                      <label htmlFor="WebDevelopmentMetaDesc" className="form-label">Meta Description</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Meta Description"
                        name="WebDevelopmentMetaDesc"
                        value={form.WebDevelopmentMetaDesc}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <h6 className="mb-3 fs-6">Blogs:</h6>
                    <div className="form-group col">
                      <label htmlFor="BlogMetaTitle" className="form-label">Meta Title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Meta Title"
                        name="BlogMetaTitle"
                        value={form.BlogMetaTitle}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group col">
                      <label htmlFor="BlogMetaDesc" className="form-label">Meta Description</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Meta Description"
                        name="BlogMetaDesc"
                        value={form.BlogMetaDesc}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-add btn-success"
                      type="submit"
                    >
                      {id ? "Update" : "Add"}
                    </button>
                  </div>
                </form>
              )
                :
                (<p className="mb-0">&nbsp;</p>)
            }
            {/* form end  */}
          </div>

        </div>
      </div>
    </div>
   </>
  );
};

export default Metas;
