import React, { useState } from 'react';
import { db } from '../../Firebase';
import * as XLSX from 'xlsx';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';

const BlogExportExcel = () => {
  const exportData = async () => {
    const data = [];
    const headers = ['id', 'title', 'description', 'tags', 'timestamp', 'metaTitle', 'metaDesc', 'slug', 'category', 'imgUrl'];

    const collectionDB = collection(db, 'blogs');
    await getDocs(collectionDB).then((snapshot) => {
      snapshot.forEach((doc) => {
        const docData = doc.data();
        data.push({
          id: doc.id,
          title: docData.title,
          description: docData.description,
          metaTitle: docData.metaTitle,
          metaDesc: docData.metaDesc,
          slug: docData.slug,
          category: docData.category,
          tags: Array.isArray(docData.tags) ? docData.tags.join(',') : '',
          imgUrl: docData.imgUrl,
          timestamp: docData.timestamp,
        });
      });

      const ws = XLSX.utils.json_to_sheet(data, { header: headers });
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'outsourcingtopakistan.xlsx');
    })
      .catch((error) => {
        console.error(error);
      });
  };

  // import data 
  const [selectedFile, setSelectedFile] = useState(null);
  const [importedData, setImportedData] = useState(null);

  const handleFileInputChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleImportButtonClick = async () => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(selectedFile);
  
    fileReader.onload = async e => {
      const binaryString = e.target.result;
      const workbook = XLSX.read(binaryString, { type: "binary" });
  
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const rows = XLSX.utils.sheet_to_json(sheet);
  
      // Define the header fields
      const headerFields = ["id", "title", "description", "tags", "timestamp", "metaTitle", "metaDesc", "slug", "category", "imgUrl"];
  
      // Get the header row as an array of field names
      const headerRow = Object.keys(rows[0]);
  
      // Map the rows to new objects using the header row as field names
      const data = rows.map(row => {
        const obj = {};
        headerFields.forEach((field, index) => {
          if (field === "tags") {
            const fieldValue = headerRow.includes(field) ? row[headerRow[index]] : "";
            obj[field] = fieldValue.split(",").map(tag => tag.trim());
          } else {
            obj[field] = headerRow.includes(field) ? row[headerRow[index]] : "";
          }
        });
        return obj;
      });
  
      const blogsRef = collection(db, "blogs");
      try {
        const existingDocs = await getDocs(blogsRef);
        const existingIds = existingDocs.docs.map(doc => doc.id);
        const uploadedIds = data.map(row => row.id); // Moved this line before it is referenced
        let newData = data;
        if (existingIds.length > 0) {
          newData = data.filter(row => !existingIds.includes(row.id) && !uploadedIds.includes(row.id)); // Added check for existing and uploaded IDs
        }
        if (newData.length > 0) {
          await Promise.all(newData.map(row => addDoc(blogsRef, row)));
          setImportedData(newData);
          toast.success("Data imported successfully!");
        } else {
          setImportedData([]);
          toast.warn("No new data to import!");
        }
      } catch (error) {
        console.error(error);
        toast.error("Error importing data!");
      }
    };
  };
  
  

  return (
    <>
      <div className="col-md-3">
        <input type="file" className='form-control shadow-none' onChange={handleFileInputChange} />
      </div>
      <div className="col-md-3 my-lg-0 my-2 text-center">
        <button className="btn btn-info text-white me-2" disabled={!selectedFile} onClick={handleImportButtonClick}>
          Import
        </button>
        <button className="btn btn-info text-white" onClick={exportData}>
          Export to Excel
        </button>
      </div>
    </>
  );
};

export default BlogExportExcel;
