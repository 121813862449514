import "../assets/css/footer.css";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMapMarker,
} from "@fortawesome/fontawesome-free-solid";
import Logo from "../assets/images/logo.webp";

const footer = () => {
  return (
    <footer className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-4 footer-left-column">
            <h3>
              let’s build <span className="text-green">something</span> awesome
            </h3>
            <ul className="info">
              <li>
                <FontAwesomeIcon icon={faPhone} />
                <NavLink to="tel:209-227-2277">+(209) 227 2277 </NavLink>
              </li>
              <li>
                <FontAwesomeIcon icon={faEnvelope} />
                <NavLink to="mailto:sales@outsourcingtopakistan.com">sales@outsourcingtopakistan.com </NavLink>
              </li>
              <li>
                <FontAwesomeIcon icon={faMapMarker} />
                <span> 4 New Hyde Park Franklin Square New York </span>
              </li>
            </ul>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-3 foot-links">
                <h4>Quick Link</h4>
                <ul>
                  <li>
                    <NavLink to="/"> Home</NavLink>
                  </li>
                  <li>
                    <NavLink to="/web-development"> Web development</NavLink>
                  </li>
                  <li>
                    <NavLink to="/about-us"> About Us</NavLink>
                  </li>
                  <li>
                    <NavLink to="/blogs"> Blogs</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact-us"> Contact Us</NavLink>
                  </li>
                </ul>
              </div>
              <div className="col-md-5 foot-links">
                <h4>Outsourcing Plan</h4>
                <ul>
                  <li>
                    <NavLink to="#"> White Labelling</NavLink>
                  </li>
                  <li>
                    <NavLink to="#"> Resource Outsourcing</NavLink>
                  </li>
                  <li>
                    <NavLink to="#"> Agency Startup</NavLink>
                  </li>
                  <li>
                    <NavLink to="#"> Agency Branding</NavLink>
                  </li>
                </ul>
              </div>
              <div className="col-md-4 foot-links">
                <h4>Services</h4>
                <ul>
                  <li>
                    <NavLink to="#"> Creative Designing</NavLink>
                  </li>
                  <li>
                    <NavLink to="#"> Web Development</NavLink>
                  </li>
                  <li>
                    <NavLink to="#"> Social Marketing</NavLink>
                  </li>
                  <li>
                    <NavLink to="#"> Lead Generation</NavLink>
                  </li>
                  <li>
                    <NavLink to="#"> Website Traffic</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="credit">
          <NavLink to="/" className="dcma me-3">
            <img className="img-fluid" src={Logo} alt="" />
          </NavLink>
          <NavLink to="#" className="dcma">
            <img className="img-fluid" alt="" src="https://images.dmca.com/Badges/DMCA_logo-std-btn160w.png?ID=c7d7a712-5309-48ec-9fe6-8b71cdfc1115" />
          </NavLink>
          <small>Copyright {new Date().getFullYear()} Outsourcing to Pakistan – Powered by PQ Media LLC</small>
        </div>

      </div>
    </footer>
  );
};

export default footer;
