import { useState, useEffect } from 'react';
import { db } from '../Firebase';
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { excerpt } from '../components/Excerpt';
import SearchBar from "../components/SearchBar";
import ReUsable from '../components/ReUsable';

const Blog = ({ handleModalShow }) => {

  // view blogs start 
  const [Blogs, setBlogs] = useState([]);
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const [meta, setMeta] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [blogsPerPage, setBlogsPerPage] = useState(20);

  useEffect(() => {
    getBlogs();
  }, []);

  const getBlogs = async () => {
    const blogRef = collection(db, "blogs");
    const orderedBlogs = query(blogRef, orderBy("timestamp", 'desc'));
    const docSnapshot = await getDocs(orderedBlogs);
    setBlogs(docSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    setRelatedBlogs(docSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  };

  const fetchMeta = async () => {
    const BlogColl = collection(db, "metaData");
    await getDocs(BlogColl).then((querySnapshot) => {
      const newData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      setMeta(newData);
    })
  };

  useEffect(() => {
    fetchMeta();
  }, []);

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = Blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(Blogs.length / blogsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumbers) => setCurrentPage(pageNumbers);



  return (
    <>
      <Helmet>
        <title>{meta && meta[0]?.BlogMetaTitle ? meta[0]?.BlogMetaTitle : "Blogs"}</title>
        <meta name="description" content={meta && meta[0]?.BlogMetaDesc ? meta[0]?.BlogMetaDesc : "Blogs Description"} />
      </Helmet>

      <div className="blog-banner">
        <div className="container">
          <div className="row">
            <div className="col content text-center">
              <h1>
                <b>Blogs</b>
              </h1>
            </div>
          </div>
        </div>
      </div>

      {/* post content section start  */}
      <div className="post-content py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              {currentBlogs.length === 0 ? (
                <h6 className='text-dark fs-4'>No Blogs Found</h6>
              ) : (
                currentBlogs?.map((Blog, index) => (
                  <div key={index} className="single-post mb-5">
                    <div className="imgbx">
                      <img
                        className="img-fluid"
                        src={Blog.imgUrl}
                        alt={Blog.title}
                      />
                    </div>
                    <div className="content">
                      <NavLink to={`/blog/${Blog.slug}`} className="post-title">
                        <h5 className='my-3 fs-4'>
                          {Blog.title}
                        </h5>
                      </NavLink>
                      <p>
                        {/* {excerpt(Blog.description, 170)} */}
                        <div dangerouslySetInnerHTML={{ __html: excerpt(Blog.description, 170) }} />
                      </p>
                      <div className="mt-4 ms-5">
                        <button className='main-btn-tn'>
                          <NavLink to={`/blog/${Blog.slug}`}>
                            <span>Read More</span>
                          </NavLink>
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              )}

              {currentBlogs.length !== 0 ? (

                <div className="d-flex justify-content-center">

                  <ul className="pagination">
                    <li className="page-item">
                      <button
                        className="page-link shadow-none"
                        disabled={currentPage <= 1}
                        onClick={() => setCurrentPage(currentPage - 1)}
                      >
                        Previous
                      </button>
                    </li>
                    {pageNumbers.map((number, index) => (
                      <li
                        key={index}
                        className={`page-item ${currentPage === number ? 'active' : ''}`}
                      >
                        <button
                          className="page-link shadow-none"
                          onClick={() => setCurrentPage(number)}
                        >
                          {number}
                        </button>
                      </li>
                    ))}

                    <li
                      className={`page-item ${currentPage === pageNumbers[pageNumbers.length - 1] ? 'disabled' : ''
                        }`}
                    >
                      <button
                        className="page-link shadow-none"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        aria-label="Next"
                      >
                        Next
                      </button>
                    </li>
                  </ul>


                </div>
              ) : (
                <></>
              )}


            </div>
            <div className="col-md-4 sidebar">
              <div className="sidebar-wrap">

                {/* Search Bar */}

                <SearchBar setBlogs={setBlogs} getBlogs={getBlogs} Blogs={Blogs} />

                <div className="popular-post">
                  <h4 className="popular-title">Recent Posts</h4>
                  <div className="popular-post-list mt-4">
                    {
                      relatedBlogs?.map((Blog, i) => (
                        <div key={i} className="single-post-list mb-3 d-flex flex-row align-items-center">
                          <div className="thumb me-3">
                            <img
                              className="img-fluid"
                              src={Blog.imgUrl}
                              alt={Blog.title}
                            />
                          </div>
                          <div className="details">
                            <NavLink to={`/blog/${Blog.slug}`}>
                              <h6>{excerpt(Blog.title, 35)}</h6>
                            </NavLink>
                            <p>{Blog.timestamp}</p>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* post content section end  */}

      <ReUsable handleModalShow={handleModalShow} />
    </>
  );
};

export default Blog;
