import { NavLink } from "react-router-dom";
import { excerpt } from '../components/Excerpt';

const Card = ({ title, imgUrl, slug, timestamp }) => {
  return (
    <div className="single-post-list mb-3 d-flex flex-row align-items-center">
      <div className="thumb me-3">
        <img
          className="img-fluid"
          src={imgUrl}
          alt={title}
        />
      </div>
      <div className="details">
        <NavLink to={`/blog/${slug}`}>
          <h6>{excerpt(title,35)}</h6>
        </NavLink>
        <p>{timestamp}</p>
      </div>
    </div>
  );
};

export default Card;
