import { useState, useEffect } from "react";
import { db } from '../Firebase';
import { collection, getDocs } from "firebase/firestore";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReUsable from "../components/ReUsable"
import php from "../assets/__images/php.png"
import node from "../assets/__images/node.png"
import angular from "../assets/__images/angular.png"
import wp from "../assets/__images/wp.png"
import phpWhite from "../assets/__images/php-white.png"
import angularWhite from "../assets/__images/angular-white.png"
import shopifyWhite from "../assets/__images/shopify-white.png"
import wooWhite from "../assets/__images/woo-white.png"
import crm from "../assets/__images/crm.png"
import erp from "../assets/__images/erp.png"
import cloud from "../assets/__images/cloud.png"
import woo from "../assets/__images/woo.png"
import magento from "../assets/__images/magento.png"
import webDevImg from "../assets/images/men-sit.webp"
import webDevProcessImg from "../assets/__images/webDevProcessImg.jpg"
import MultiStepForm from "../components/MultiStepForm";

const WebDevelopment = ({ handleModalShow }) => {

  const [meta, setMeta] = useState([]);

  const fetchMeta = async () => {
    const BlogColl = collection(db, "metaData");
    await getDocs(BlogColl).then((querySnapshot) => {
      const newData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      setMeta(newData);
    })
  }
  useEffect(() => {
    fetchMeta();
  }, [])

  return (
    <>
      <Helmet>
        <title>{meta && meta[0]?.WebDevelopmentMetaTitle ? meta[0]?.WebDevelopmentMetaTitle : "Web Development"}</title>
        <meta name="description" content={meta && meta[0]?.WebDevelopmentMetaDesc ? meta[0]?.WebDevelopmentMetaDesc : "Web Development Description"} />
      </Helmet>
      <div className="web-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col content text-center">
              <h1>
                <b>Unlock the Power of Web Development with Our Expertise</b>
              </h1>
              <p className="pt-2 text-white">
                Elevate Your Online Presence Today!
              </p>
              <div className="mt-5">
                <button className="main-btn-tn">
                  <NavLink to="#" className="text-white" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* about section start  */}
      <div className="about-us py-5">
        {/* cirlce image  start*/}
        <span className="topcirleimg"></span>
        {/* cirlce image  end*/}
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 title">
              <h2>Web Development</h2>
              <h6 className="fw-normal pt-3">Connect Business With Target Audience</h6>
            </div>
            <div className="col-md-6 content">
              <p>
                Outsource your need to us with our Web Development Services, your one-stop solution for all your web development needs. We specialize in two core areas: Outsourced Web Development and Staff Augmentation for Web Development. Whether you're a business looking to outsource your web development projects or need experienced developers to join your in-house team, we have the expertise and resources to fulfill your requirements.
              </p>
            </div>
          </div>

          <div className="row align-items-center pt-5">
            <div className="col-md-4">
              <ul className="dot-left">
                <li>BRAND WEBSITE</li>
                <li>ECOMMERCE STORE</li>
                <li>MULTI VENDOR</li>
              </ul>
            </div>
            <div className="col-md-4">
              <div className="centered-img">
                <img
                  className="updown img-fluid fivenine-img"
                  src={webDevImg}
                  alt="Web Development"
                />
              </div>
            </div>
            <div className="col-md-4">
              <ul className="dot-right">
                <li>API INTEGRATION</li>
                <li>WEB APPLICATIONS</li>
                <li>PLUGIN CUSTOMIZATION</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* about section end  */}

      {/* service process section start  */}
      <div className="serviceProcess py-5">
        <div className="container">
          <div className="designer-box">
            <div className="title text-center mb-5">
              <h2 className="fs-1">
                <span className="animate">Process</span>
                Web Development
              </h2>
            </div>
            <div className="imgbx text-center">
              <img
                src={webDevProcessImg}
                className="img-fluid"
              />
            </div>
            <div className="mt-5 text-center">
              <button className="main-btn-tn">
                <NavLink to="#" onClick={handleModalShow}>
                  <span>Get Started</span>
                </NavLink>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* service process section end  */}

      {/* info section start  */}
      <div className="info-section py-5">
        <div className="container">
          <div className="content text-center py-3">
            <h2 className="text-white fs-1 pb-3">
              <span className="animate">Outsourced</span>
              Web Development
            </h2>
            <p className="text-white">
              Outsource your web development projects to our highly skilled team and enjoy the benefits of cost-efficiency, faster project delivery, and access to a wide range of expertise.
            </p>
          </div>
        </div>
      </div>
      {/* info section end  */}

      {/* light plan section start  */}
      <div className="light-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Cost-Effective Solutions</h3>
              <p className="pt-3">
                Our outsourced web development services offer a cost-effective alternative to in-house development. You'll save on recruitment, infrastructure, and training costs.
              </p>
              {/* <ul className="pt-3">
                <li>
                  <img
                    className="img-fluid"
                    src={wp}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={php}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={angular}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={node}
                  />
                </li>
              </ul>
              <div className="mt-3 ms-5">
                <button className="main-btn-tn">
                  <NavLink to="#" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* light plan section end  */}

      {/* starter plan section start  */}
      <div className="starter-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 content">
              <h3 className="text-white">Rapid Project Delivery </h3>
              <p className="pt-3 text-white">
                We pride ourselves on quick project turnaround times. Our dedicated team of developers ensures your projects are completed efficiently, meeting your timelines.
              </p>
              {/* <ul className="pt-3">
                <li>
                  <img
                    className="img-fluid"
                    src={wooWhite}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={shopifyWhite}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={phpWhite}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={angularWhite}
                  />
                </li>
              </ul>
              <div className="mt-3 ms-5">
                <button className="main-btn-tn">
                  <NavLink to="#" className="text-white" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div> */}
            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
      {/* starter plan section end  */}

      {/* multivendor plan section start  */}
      <div className="multivendor-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Expertise Across Technologies</h3>
              <p className="pt-3">
                Our developers have experience with a variety of technologies and frameworks, making us a versatile partner for a wide range of projects.
              </p>
              {/* <ul className="pt-3">
                <li>
                  <img
                    className="img-fluid"
                    src={woo}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={magento}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={php}
                  />
                </li>
              </ul>
              <div className="mt-3 ms-5">
                <button className="main-btn-tn">
                  <NavLink to="#" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* multivendor plan section end  */}

      {/* application plan section start  */}
      <div className="application-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 content">
              <h3 className="text-white">Staff Augmentation for Web Development:</h3>
              <p className="pt-3 text-white">
                If you're looking to expand your in-house development team, our staff augmentation services provide you with experienced developers who seamlessly integrate with your team.
              </p>
              {/* <ul className="pt-3">
                <li>
                  <img
                    className="img-fluid"
                    src={crm}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={erp}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={cloud}
                  />
                </li>
              </ul>
              <div className="mt-3 ms-5">
                <button className="main-btn-tn">
                  <NavLink to="#" className="text-white" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div> */}
            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
      {/* application plan section end  */}

      {/* multivendor plan section start  */}
      <div className="multivendor-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Experienced Professionals</h3>
              <p className="pt-3">
                We offer access to a pool of experienced web developers who can augment your team's skills and capabilities.
              </p>
              {/* <ul className="pt-3">
                <li>
                  <img
                    className="img-fluid"
                    src={woo}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={magento}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={php}
                  />
                </li>
              </ul>
              <div className="mt-3 ms-5">
                <button className="main-btn-tn">
                  <NavLink to="#" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* multivendor plan section end  */}

      {/* starter plan section start  */}
      <div className="starter-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 content">
              <h3 className="text-white">Flexible Scaling </h3>
              <p className="pt-3 text-white">
                You can scale your team up or down as needed, ensuring that you have the right resources for your projects without the hassle of permanent hires.
              </p>
              {/* <ul className="pt-3">
                <li>
                  <img
                    className="img-fluid"
                    src={wooWhite}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={shopifyWhite}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={phpWhite}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={angularWhite}
                  />
                </li>
              </ul>
              <div className="mt-3 ms-5">
                <button className="main-btn-tn">
                  <NavLink to="#" className="text-white" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div> */}
            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
      {/* starter plan section end  */}

      {/* light plan section start  */}
      <div className="light-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Efficient Collaboration</h3>
              <p className="pt-3">
                Our developers are not just skilled in coding but also in effective collaboration, ensuring that they work seamlessly with your in-house team.
              </p>
              {/* <ul className="pt-3">
                <li>
                  <img
                    className="img-fluid"
                    src={wp}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={php}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={angular}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={node}
                  />
                </li>
              </ul>
              <div className="mt-3 ms-5">
                <button className="main-btn-tn">
                  <NavLink to="#" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* light plan section end  */}

      <div className="why-choose-us testimonials py-5">
        <div className="container">
          <div className="title text-center mb-3">
            <h2>
              Why Choose Us
            </h2>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="testi-box shadow_sm m-2">
                <h6>Proven Track Record</h6>
                <p className='py-3'>
                  With a history of successful projects, we have the experience and expertise to handle complex web development tasks.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="testi-box shadow_sm m-2">
                <h6>Quality Assurance</h6>
                <p className='py-3'>
                  Our commitment to quality is unwavering. We follow best practices and quality standards to deliver high-quality web solutions.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="testi-box shadow_sm m-2">
                <h6>Dedicated Support</h6>
                <p className='py-3'>
                  We offer 24/7 support to address your concerns and ensure your projects run smoothly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* plans section start  */}
      <div className="plans-slide py-5">
        <div className="container">
          <div className="title text-center px-5">
            <h2 className="mb-3">
              <span className="animate">Plans</span>
              Web Development
            </h2>
            <p>
              Our pricing plans are personalized to meet your business objective
              and add value to it. Choose a package that best fits your
              requirement and get started.
            </p>
          </div>

          {/* <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={20}
            slidesPerView={3}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            navigation
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
            autoplay={{ delay: 2000 }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            <SwiperSlide>
              <div className="plan-box">
                <h6 className="mb-4">Agency Startup</h6>
                <ul>
                  <li>5 to 10 Pages with Admin Panel</li>
                  <li>Custom Design &amp; HTML</li>
                  <li>3 Sliding Banners</li>
                  <li>API Integration</li>
                  <li>Basic SEO Setup</li>
                  <li>Mobile Responsive</li>
                  <li>Free Hosting with 10 Emails</li>
                  <li>Free Maintenance (15 Days)</li>
                  <li>Delivery (1 month)</li>
                </ul>
                <p className="fs-4 fw-bold">USD 2,500</p>
                <div className="capsule-normal-btn mt-4">
                  <NavLink to="#" onClick={handleModalShow}>Get Started</NavLink>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="plan-box">
                <h6 className="mb-4">White Labelling</h6>
                <ul>
                  <li>5 to 10 Pages with Admin Panel</li>
                  <li>Custom Design &amp; HTML</li>
                  <li>3 Sliding Banners</li>
                  <li>API Integration</li>
                  <li>Basic SEO Setup</li>
                  <li>Mobile Responsive</li>
                  <li>Free Hosting with 10 Emails</li>
                  <li>Free Maintenance (15 Days)</li>
                  <li>Delivery (1 month)</li>
                </ul>
                <p className="fs-4 fw-bold">USD 500</p>
                <div className="capsule-normal-btn mt-4">
                  <NavLink to="#" onClick={handleModalShow}>Get Started</NavLink>
                </div>
              </div>
            </SwiperSlide>
          </Swiper> */}

          <div>
            <div className="row pt-4 justify-content-center">
              <div className="col-md-6">
                <div className="card bg_dark">
                  <div className="card-header bg-black border-0">
                    <h3 className='text-white text-center'>Contact us</h3>
                  </div>
                  <div className="card-body">
                    <MultiStepForm PageNameTracking="Web development Page" />
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
      {/* plans section end  */}

      <ReUsable handleModalShow={handleModalShow} />

      
    </>
  );
};

export default WebDevelopment;
