import { useState, useEffect } from "react";
import ReactTagInput from "@pathofdev/react-tag-input";
import { db, storage } from "../../Firebase";
import { useNavigate, useParams } from "react-router-dom";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import {
  addDoc,
  collection,
  getDoc,
  serverTimestamp,
  doc,
  updateDoc,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { Editor } from '@tinymce/tinymce-react';

const initialState = {
  title: "",
  slug: "",
  tags: [],
  category: "",
  description: "",
};

const categoryOption = [
  "Fashion",
  "Technology",
  "Food",
  "Politics",
  "Sports",
  "Business",
];

const AddEditBlog = ({ user, setActive }) => {
  const [form, setForm] = useState(initialState);
  const [file, setFile] = useState("");
  const [progress, setProgress] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const [description, setDescription] = useState('');

  const { id } = useParams();

  const navigate = useNavigate();

  const { title, slug, metaTitle, metaDesc, tags, category } = form;

  useEffect(() => {
    const uploadFile = () => {
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
          switch (snapshot.state) {
            case "paused":
              break;
            case "running":
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
            toast.info("Image upload to firebase successfully");
            setIsUploaded(true)
            setForm((prev) => ({ ...prev, imgUrl: downloadUrl }));
          });
        }
      );
    };

    file && uploadFile();
  }, [file]);

  useEffect(() => {
    id && getBlogDetail();
  }, [id]);

  const getBlogDetail = async () => {
    const docRef = doc(db, "blogs", id);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      const data = snapshot.data();
      setForm({ ...data });
      setDescription(data.description);
    } else {
      return navigate("/blog-crud");
    }
    // setActive(null);
  };

  const handleDescriptionChange = (content, editor) => {
    setDescription(content);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "title") {
      const slugValue = value.toLowerCase().replace(/[^a-z0-9-]/g, '-').replace(/-+/g, '-');
      setForm({ ...form, [name]: value, slug: slugValue });
    } else {
      setForm({ ...form, [name]: value });
    }
  };


  const handleTags = (tags) => {
    setForm({ ...form, tags });
  };

  const onCategoryChange = (e) => {
    setForm({ ...form, category: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (category && tags && title && slug && description) {
      if (!id) {
        try {
          const date = new Date();
          const formattedDate = date.toDateString();
          await addDoc(collection(db, "blogs"), {
            ...form,
            description: description,
            timestamp: formattedDate,
          });
          toast.success("Blog created successfully");
        } catch (err) {
          console.log(err);
        }
      } else {
        try {
          await updateDoc(doc(db, "blogs", id), {
            ...form,
            description: description,
          });
          toast.success("Blog updated successfully");
        } catch (err) {
          console.log(err);
        }
      }
    } else if (!tags || tags.length == 0) {
      return toast.error("Please add a tag");
    } else {
      return toast.error("All fields are mandatory to fill");
    }

    navigate("/blog-crud");
  };

  return (
    <>
      <Helmet>
        <title>{id ? form.title : "Add a new Blog"}</title>
        <meta name="description" content={id ? form.title : "Add a new Blog"} />
      </Helmet>
      <div className="addeditblog my-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="card border-0 shadow">
                <div className="card-body">
                  {/* form start  */}
                  <h2 className="text-dark fs-4 text-center mb-3">{id ? "Update Blog" : "Create Blog"}</h2>
                  <form className="blog-form" onSubmit={handleSubmit}>
                    <div className="form-group mb-3">
                      <input
                        type="text"
                        className="form-control input-text-box shadow-none"
                        placeholder="Title"
                        name="title"
                        value={form.title}
                        required
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <input
                        type="text"
                        className="form-control input-text-box shadow-none"
                        placeholder="Slug"
                        name="slug"
                        required
                        value={form.slug}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <ReactTagInput
                        required
                        tags={tags}
                        placeholder="Tags"
                        onChange={handleTags}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <select
                        value={category}
                        onChange={onCategoryChange}
                        className="catg-dropdown form-control shadow-none"
                        required
                      >
                        <option value="" hidden>Please select category</option>
                        {categoryOption.map((option, index) => (
                          <option value={option || ""} key={index}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group mb-3">
                      {/* <textarea
                        className="form-control description-box shadow-none"
                        placeholder="Description"
                        value={description}
                        required
                        name="description"
                        id="editor"
                        cols="30" rows="5"
                        onChange={handleChange}
                      /> */}
                      <Editor
                        apiKey='n5ftaj4q4gis0qh2gc6m6f3u9hkqpvmnau6lgksbwror6uql'
                        name="description"
                        initialValue={description}
                        value={description}
                        init={{
                          plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                          toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                        }}
                        onEditorChange={handleDescriptionChange}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <input
                        type="text"
                        className="form-control input-text-box shadow-none"
                        placeholder="Meta Title"
                        name="metaTitle"
                        required
                        value={metaTitle}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <input
                        type="text"
                        className="form-control input-text-box shadow-none"
                        placeholder="Meta Desc"
                        name="metaDesc"
                        required
                        value={metaDesc}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control input-text-box shadow-none"
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                    </div>
                    <div className="form-group mb-3">
                      {isUploaded || id ? (
                        <button type="submit" className="btn btn-primary shadow-none w-100">{id ? "Update Blog" : "Create Blog"}</button>
                      ) : (
                        <button type="submit" className="btn btn-primary shadow-none w-100" disabled>
                          {id ? "Update Blog" : "Create Blog"}
                        </button>
                      )}
                    </div>
                  </form>

                  {/* form end  */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEditBlog;