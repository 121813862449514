import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import { NavLink } from "react-router-dom";

const images = [
  require("../assets/images/portfolio/20doller.webp"),
  require("../assets/images/portfolio/american.webp"),
  require("../assets/images/portfolio/baber.webp"),
  require("../assets/images/portfolio/bakemybook.webp"),
  require("../assets/images/portfolio/bhook.webp"),
  require("../assets/images/portfolio/broadband.webp"),
  require("../assets/images/portfolio/cbd.webp"),
  require("../assets/images/portfolio/cbdrelief.webp"),
  require("../assets/images/portfolio/concierge.webp"),
  require("../assets/images/portfolio/DRQB.webp"),
  require("../assets/images/portfolio/elitepower.webp"),
  require("../assets/images/portfolio/eliteschool.webp"),
  require("../assets/images/portfolio/ewriting.webp"),
  require("../assets/images/portfolio/fleet.webp"),
  require("../assets/images/portfolio/fvrco.webp"),
  require("../assets/images/portfolio/gogo.webp"),
  require("../assets/images/portfolio/hireacar.webp"),
  require("../assets/images/portfolio/ifaa.webp"),
  require("../assets/images/portfolio/kueball.webp"),
  require("../assets/images/portfolio/lionfish.webp"),
  require("../assets/images/portfolio/loneoakrv.webp"),
  require("../assets/images/portfolio/maxcarehc.webp"),
  require("../assets/images/portfolio/msi.webp"),
  require("../assets/images/portfolio/quantum-leap.webp"),
  require("../assets/images/portfolio/quran.webp"),
  require("../assets/images/portfolio/realtionship.webp"),
  require("../assets/images/portfolio/sandra-canada.webp"),
  require("../assets/images/portfolio/search-boosters.webp"),
  require("../assets/images/portfolio/seopro.webp"),
  require("../assets/images/portfolio/ssbc.webp"),
  require("../assets/images/portfolio/steve.webp"),
  require("../assets/images/portfolio/stitchy.webp"),
  require("../assets/images/portfolio/wolf.webp"),
  require("../assets/images/portfolio/thestoss.webp"),
  require("../assets/images/portfolio/tripz.webp"),
  require("../assets/images/portfolio/vpnboosters.webp"),
  require("../assets/images/portfolio/wheels.webp")
];

const BrandingBoxes = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const handleOpen = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handlePrev = () => {
    setPhotoIndex((photoIndex + images.length - 1) % images.length);
  };

  const handleNext = () => {
    setPhotoIndex((photoIndex + 1) % images.length);
  };

  return (
    <div className="my-5">
      <div className="container" style={{ maxWidth: "1260px" }}>
        <div className="row">
          {images.map((image, index) => (
            <div className="col-md-3 mb-3">
              <NavLink key={index} onClick={() => handleOpen(index)}>
                <img src={image} className="img-fluid shadow" loading="lazy" />
              </NavLink>
            </div>
          ))}
        </div>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={handleClose}
          onMovePrevRequest={handlePrev}
          onMoveNextRequest={handleNext}
        />
      )}
    </div>
  );
};

export default BrandingBoxes;
