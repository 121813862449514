import React from "react";
import ReactDOM from "react-dom/client";
import "remixicon/fonts/remixicon.css";
import "bootstrap/dist/css/bootstrap.css";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "@pathofdev/react-tag-input/build/index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import 'react-image-lightbox/style.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ToastContainer theme="dark" pauseOnHover="false" />
    <App />
  </BrowserRouter>
);
