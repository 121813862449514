const Loading = () => {
    return (
        <div className='loading d-flex justify-content-center align-items-center min-vh-100'>
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="spinner-grow" style={{ width: "5rem", color: "#19c5c2", height: "5rem" }} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Loading