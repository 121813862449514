import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faCheck } from "@fortawesome/fontawesome-free-solid";
import ReUsable from "../components/ReUsable";

const ThankYou = ({ handleModalShow }) => {
  return (
    <>
       <Helmet>
        <title>Thanks You</title>
        <meta name="description" content="404 Page Description" />
      </Helmet>
      <div className="error py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-6 text-center">
              <div className="card border-0 shadow-sm">
                  <div className="card-header">
                  <FontAwesomeIcon className="rounded-circle p-2 fs-1 text-white" style={{background:"#00E95B"}} icon={faCheck} />
                  </div>
                <div className="card-body">
                  <h2 className="mb-4 fw-bold py-2">Thanks You!</h2>
                  <button className="main-btn-tn pb-3"><NavLink className="text-dark" to="/"><span><FontAwesomeIcon icon={faHome} className="text-white"/> Back to Home</span></NavLink></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReUsable handleModalShow={handleModalShow} />
    </>
  )
}

export default ThankYou