import { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import BrandingBoxes from "./BrandingBoxes";
import SocialMediaBoxes from "./SocialMediaBoxes";
import VideoDevelopmentBoxes from "./VideoDevelopmentBoxes";

const PortfolioTabs = () => {

    const tabs = [
        {
            id: 1,
            title: "Branding & Creative",
            content: <BrandingBoxes />,
        },
        // {
        //     id: 2,
        //     title: "Web Development",
        //     content: "Content for Tab 2",
        // },
        // {
        //     id: 3,
        //     title: "App Development",
        //     content: "Content for Tab 3",
        // },
        // {
        //     id: 4,
        //     title: "Social Media",
        //     content: <SocialMediaBoxes />,
        // },
        // {
        //     id: 5,
        //     title: "Video Development",
        //     content: <VideoDevelopmentBoxes />,
        // },
    ];

    const [activeTab, setActiveTab] = useState(tabs[0].id);

    return (
        <div className="portfolio-tabs text-center my-5">
            <Nav className="justify-content-center">
                {tabs.map((tab) => (
                    <NavItem key={tab.id}>
                        <NavLink
                            active={activeTab === tab.id}
                            onClick={() => setActiveTab(tab.id)}
                        >
                            <button>
                                {tab.title}
                            </button>
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            <TabContent activeTab={activeTab}>
                {tabs.map((tab) => (
                    <TabPane key={tab.id} tabId={tab.id}>
                        {tab.content}
                    </TabPane>
                ))}
            </TabContent>
        </div>
    );
};

export default PortfolioTabs;

