import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { faTimes, faBars, faUser, faChevronDown } from "@fortawesome/fontawesome-free-solid";
import "../assets/css/header.css";
import Logo from "../assets/images/logo.webp";

const Header = ({ user, handleLogout, handleModalShow }) => {

  const userId = user?.uid;

  return (<>
    <nav>
      <div className="wrapper">
        <div className="logo">
          <NavLink to="/"><img src={Logo} alt="" className="img-fluid" /></NavLink>
        </div>
        <input type="radio" name="slider" id="menu-btn" />
        <input type="radio" name="slider" id="close-btn" />
        <ul className="nav-links">
          <label htmlFor="close-btn" className="btn close-btn">
            <FontAwesomeIcon icon={faTimes} />
          </label>
          {userId ? (
            <>
              <li>
                <NavLink to="/">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/blog-crud">
                  Blog CRUD
                </NavLink>
              </li>
              <li>
                <NavLink to="/meta-datas">
                  Metas CRUD
                </NavLink>
              </li>
              <li>
                <NavLink to="#" className="desktop-item">
                  <FontAwesomeIcon icon={faUser} /> {user?.displayName} <FontAwesomeIcon icon={faChevronDown} />
                </NavLink>
                <input type="checkbox" id="showDrop" />
                <label htmlFor="showDrop" className="mobile-item">
                  <FontAwesomeIcon icon={faUser} /> {user?.displayName} <FontAwesomeIcon icon={faChevronDown} />
                </label>
                <ul className="drop-menu">
                  <li onClick={handleLogout}>
                    <NavLink to="#">logout</NavLink>
                  </li>
                </ul>
              </li>

            </>
          ) : (
            <>
              <li>
                <NavLink to="/">Home</NavLink>
              </li>

              <li>
                <NavLink to="#" className="desktop-item">
                  Services <FontAwesomeIcon icon={faChevronDown} />
                </NavLink>
                <input type="checkbox" id="showDrop" />
                <label htmlFor="showDrop" className="mobile-item">
                  Services <FontAwesomeIcon icon={faChevronDown} />
                </label>
                <ul className="drop-menu">
                  <li>
                    <NavLink to="/web-development">Web Development</NavLink>
                  </li>
                </ul>
              </li>

              {/* <li>
                  <NavLink to="#" className="desktop-item">
                    Services
                  </NavLink>
                  <input type="checkbox" id="showDrop" />
                  <label htmlFor="showDrop" className="mobile-item">
                    Services
                  </label>
                  <ul className="drop-menu">
                    <li>
                      <NavLink to="web-development">Web Development</NavLink>
                    </li>
                    <li>
                      <NavLink to="#">Drop menu 2</NavLink>
                    </li>
                    <li>
                      <NavLink to="#">Drop menu 3</NavLink>
                    </li>
                    <li>
                      <NavLink to="#">Drop menu 4</NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink to="#" className="desktop-item">
                    Mega Menu
                  </NavLink>
                  <input type="checkbox" id="showMega" />
                  <label htmlFor="showMega" className="mobile-item">
                    Mega Menu
                  </label>
                  <div className="mega-box">
                    <div className="content">
                      <div className="row">
                        <img
                          src="https://dummyimage.com/270x225/a1a1a1/ffffff"
                          alt=""
                        />
                      </div>
                      <div className="row">
                        <header>Design Services</header>
                        <ul className="mega-links">
                          <li>
                            <NavLink to="#">Graphics</NavLink>
                          </li>
                          <li>
                            <NavLink to="#">Vectors</NavLink>
                          </li>
                          <li>
                            <NavLink to="#">Business cards</NavLink>
                          </li>
                          <li>
                            <NavLink to="#">Custom logo</NavLink>
                          </li>
                        </ul>
                      </div>
                      <div className="row">
                        <header>Email Services</header>
                        <ul className="mega-links">
                          <li>
                            <NavLink to="#">Personal Email</NavLink>
                          </li>
                          <li>
                            <NavLink to="#">Business Email</NavLink>
                          </li>
                          <li>
                            <NavLink to="#">Mobile Email</NavLink>
                          </li>
                          <li>
                            <NavLink to="#">Web Marketing</NavLink>
                          </li>
                        </ul>
                      </div>
                      <div className="row">
                        <header>Security services</header>
                        <ul className="mega-links">
                          <li>
                            <NavLink to="#">Site Seal</NavLink>
                          </li>
                          <li>
                            <NavLink to="#">VPS Hosting</NavLink>
                          </li>
                          <li>
                            <NavLink to="#">Privacy Seal</NavLink>
                          </li>
                          <li>
                            <NavLink to="#">Website design</NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li> */}
              <li>
                <NavLink to="/about-us">About Us</NavLink>
              </li>
              <li>
                <NavLink to="/blogs">Blogs</NavLink>
              </li>
              <li>
                <NavLink to="/portfolio">Portfolio</NavLink>
              </li>
              <li>
                <NavLink to="/contact-us" >
                  Contact us
                </NavLink>
              </li>
              <li className="getQuote">
                <NavLink to="#" className="rounded" onClick={handleModalShow}>
                  Get a Quote
                </NavLink>
              </li>
            </>
          )}
        </ul>
        <label htmlFor="menu-btn" className="btn menu-btn">
          <FontAwesomeIcon icon={faBars} id="menuBar" />
        </label>
      </div>
    </nav>
  </>
  );
};

export default Header;
