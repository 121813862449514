import { NavLink } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react";
import AmericanBookMarketing from "../assets/images/reoutsourcebrandimages/brand-1.png";
import BakeMyBook from "../assets/images/reoutsourcebrandimages/brand-2.png";
import Broadband from "../assets/images/reoutsourcebrandimages/brand-3.png";
import Carhire from "../assets/images/reoutsourcebrandimages/brand-4.png";
import CBDreliefcream from "../assets/images/reoutsourcebrandimages/brand-5.png";
import Eliteschool from "../assets/images/reoutsourcebrandimages/brand-6.png";
import eWritingPro from "../assets/images/reoutsourcebrandimages/brand-7.png";
import TheToss from "../assets/images/reoutsourcebrandimages/brand-8.png";
import Webdesignpro from "../assets/images/reoutsourcebrandimages/brand-9.png";
import VPNBoosters from "../assets/images/reoutsourcebrandimages/brand-10.png";
import IFAA from "../assets/images/reoutsourcebrandimages/brand-11.png";
import MaxCare from "../assets/images/reoutsourcebrandimages/brand-12.png";
import QuantumLeap from "../assets/images/reoutsourcebrandimages/brand-13.png";
import RelationshipDecisions from "../assets/images/reoutsourcebrandimages/brand-14.png";
import SandraCanada from "../assets/images/reoutsourcebrandimages/brand-15.png";
import SearchBoosters from "../assets/images/reoutsourcebrandimages/brand-16.png";
import SSBC from "../assets/images/reoutsourcebrandimages/brand-17.png";
import SteveandBurns from "../assets/images/reoutsourcebrandimages/brand-18.png";
import { Navigation, Pagination, Autoplay } from "swiper";
import MultiStepForm from './MultiStepForm';

const BrandIcons = [
    {
        icons: AmericanBookMarketing,
    },
    {
        icons: BakeMyBook,
    },
    {
        icons: Broadband,
    },
    {
        icons: Carhire,
    },
    {
        icons: CBDreliefcream,
    },
    {
        icons: Eliteschool,
    },
    {
        icons: eWritingPro,
    },
    {
        icons: TheToss,
    },
    {
        icons: Webdesignpro,
    },
    {
        icons: VPNBoosters,
    },
    {
        icons: IFAA,
    },
    {
        icons: MaxCare,
    },
    {
        icons: RelationshipDecisions,
    },
    {
        icons: SearchBoosters,
    },
    {
        icons: SSBC,
    },
    {
        icons: SteveandBurns,
    },
    {
        icons: SandraCanada,
    },
    {
        icons: QuantumLeap,
    },
];

const ReUsable = ({ handleModalShow }) => {

    return (
        <>
            {/* proudly section start  */}
            <div className="proudly py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4 title">
                            <h2 className='text-white'>
                                We Serve <br />
                                <span className="text-green">Proudly</span>
                            </h2>
                        </div>
                        <div className="col-md-8">
                            <Swiper
                                modules={[Navigation, Pagination, Autoplay]}
                                spaceBetween={20}
                                slidesPerView={4}
                                grabCursor={true}
                                centeredSlides={true}
                                loop={true}
                                autoplay={{ delay: 2000 }}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 2,
                                    },
                                    768: {
                                        slidesPerView: 3,
                                    },
                                    1024: {
                                        slidesPerView: 4,
                                    },
                                }}
                            >
                                {BrandIcons.map((item, i) => (
                                    <SwiperSlide key={i}>
                                        <div className="box align-middle">
                                            <div className="imgbx">
                                                <img
                                                    className="img-fluid align-middle"
                                                    src={item.icons}
                                                />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}

                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
            {/* proudly section end  */}

            {/* testimonial section start  */}
            <div className="testimonials py-5">
                <div className="container">
                    <div className="title">
                        <h2>
                            Hear what people <br />
                            <span className="text-green">Say about</span>
                        </h2>
                    </div>
                    <Swiper className='pt-5'
                        modules={[Autoplay]}
                        spaceBetween={20}
                        slidesPerView={3}
                        grabCursor={true}
                        centeredSlides={true}
                        loop={true}
                        autoplay={{ delay: 2000 }}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            1024: {
                                slidesPerView: 3,
                            },
                        }}
                    >
                        <SwiperSlide>
                            <div className="testi-box shadow_sm m-2">
                                <h6>Edward Martinez</h6>
                                <p className='py-3'>
                                    I love working with Outsourcing to Pakistan.They’re the best!
                                    Great quality, great pricing & a great team! The relationship
                                    we’ve built is phenomenal. They’ve helped my agency scale and
                                    are always there for me and my businesses best interest. I
                                    cannot thank them enough. These guys are legit!
                                </p>
                                <small>CEO Digitized Media LLC</small>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testi-box shadow_sm m-2">
                                <h6>Khalid Framarz Hamidi</h6>
                                <p className='py-3'>
                                    Outsourcing to Pakistan designed and built a responsive website for our law firm. I'm very happy with their service and professionalism of it's employees. They had the best customer service and gave quick responses.I would definitely use Outsource in Pakistan in our future IT needs, specially for a professional marketing strategy.
                                </p>
                                <small>Afghan American Legal Advisors</small>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testi-box shadow_sm m-2">
                                <h6>Jeremy Ashcraft</h6>
                                <p className='py-3'>
                                    The Team at OIP is effective and affordable. They have completed or are currently working on 4 separate projects for our company. Website builds,
                                    API integrations, Animated Videos and even outsourced administration. At this point we can only offer positive feedback on the speed, quality and cost.
                                    We completely recommend then for your next project.
                                </p>
                                <small>Karter Scientific Labware Manufacturing Co</small>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testi-box shadow_sm m-2">
                                <h6>Preet Bansal </h6>
                                <p className='py-3'>
                                    I have had a great time working with the team. These folks make an effort to understand the scope of the project and go above and beyond to deliver in a timely manner.
                                </p>
                                <small>Founder at Intrepid Marketing</small>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            {/* testimonial section end  */}

            {/* free consultation start  */}
            <div className="free-consultation py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 title">
                            <h2 className='text-white'>
                                How We Can <br />
                                <span className="text-green"> Partner</span>
                            </h2>
                            <p className="text-white pt-2 mb-5">
                                Join us in our transformational approach and find out what it&#39;s like to redefine success.
                                Our partnership goes beyond business deals; it&#39;s a collaboration that inspires,
                                innovates, and succeeds. Experience top-notch operations and turn difficulties into
                                achievements with our tailored solutions.
                            </p>
                        </div>
                        <div className="col-md-6 text-center">
                            <button className='main-btn-tn' onClick={handleModalShow}>
                                <NavLink to="#" className="text-white fs-2">
                                    <span>Schedule a Call</span>
                                </NavLink>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* free consultation end  */}

            <div className="ready py-5">
                <div className="container">
                    <div className="card border-0 rounded-4 py-4 bg-red">
                        <div className="card-body">
                            <div className="d-flex gap-4 justify-content-center align-items-center">
                                <h3 className='text-start text-white'> Are you ready to scale your business?</h3>
                                <button className="btn btn-light shadow rounded-pill text-black">
                                    <NavLink to="#" onClick={handleModalShow}>
                                        <span>Get In Touch</span>
                                    </NavLink>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

          
        </>
    )
}

export default ReUsable