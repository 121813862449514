import Autosuggest from "react-autosuggest";
import { useState, useRef } from "react";
import { faSearch } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SearchBar = ({ setBlogs, Blogs, getBlogs }) => {
  const [search, setSearch] = useState("");
  const formRef = useRef(null);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? []
      : Blogs.filter(
          (Blogs) =>
            Blogs.title.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  const [suggestions, setSuggestions] = useState([]);

  const searchBlogs = (e) => {
    e.preventDefault();
    const selectedBlog = suggestions.find(
      (suggestion) => suggestion.title === search
    );
    const filteredBlogs = selectedBlog
      ? [selectedBlog]
      : Blogs.filter((Blogs) =>
          Blogs.title.toLowerCase().includes(search.toLowerCase())
        );
    setBlogs(filteredBlogs);
  };

  const handleReset = () => {
    setSearch("");
    formRef.current.reset();
    getBlogs();
  };

  return (
    <form className="search" onSubmit={(e) => searchBlogs(e)} ref={formRef}>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={({ value }) => {
          setSuggestions(getSuggestions(value));
        }}
        onSuggestionsClearRequested={() => {
          setSuggestions([]);
        }}
        getSuggestionValue={(suggestion) => suggestion.title}
        renderSuggestion={(suggestion) => <div>{suggestion.title}</div>}
        inputProps={{
          placeholder: "Search Post",
          value: search,
          onChange: (event, { newValue }) => {
            setSearch(newValue);
          },
        }}
      />
      <button type="submit">
        <FontAwesomeIcon icon={faSearch} />
      </button>
      <button
        type="button"
        className="rounded mt-3 w-100 d-block"
        onClick={handleReset}
      >
        Reset
      </button>
    </form>
  );
};

export default SearchBar;
