import Card from "./Card";

const RelatedBlog = ({ blogs, slug }) => {
  return (
    <div className="popular-post">
      <h4 className="popular-title">
        Related Posts
      </h4>
      <div className="popular-post-list mt-4">
        {blogs?.filter((blogs) => blogs.slug !== slug).map((item) => (
            <Card {...item} />
          ))}
      </div>
    </div>
  );
};

export default RelatedBlog;
