import { useState, useEffect, useRef } from "react";
import { db } from '../Firebase';
import { collection, getDocs } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMapMarker,
} from "@fortawesome/fontawesome-free-solid";
import { NavLink, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Helmet } from "react-helmet";
import ReUsable from "../components/ReUsable"
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import ContactAnime from "../assets/__images/contact-anime.png"

const Contact = ({ handleModalShow }) => {

  const [meta, setMeta] = useState([]);

  const navigate = useNavigate();

  // email 
  const [validated, setValidated] = useState(false);
  const Contactform = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    if (validated === false) {
      setValidated(true);
    } else {
      emailjs
        .sendForm(
          "service_5h2m03q",
        "template_0ahc1wn",
        Contactform.current,
        "1AZnpvAoFrweJXY5R"
        )
        .then(
          (result) => {
            toast.info("We will notify you soon");
            navigate("/thank-you")
          },
          (error) => {
            console.log(error.text);
          }
        );
      Contactform.target.reset();
    }
  };
  // email /

  const fetchMeta = async () => {
    const BlogColl = collection(db, "metaData");
    await getDocs(BlogColl).then((querySnapshot) => {
      const newData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      setMeta(newData);
    })
  }
  useEffect(() => {
    fetchMeta();
  }, [])

  return (
    <>
      <Helmet>
        <title>{meta && meta[0]?.ContactMetaTitle ? meta[0]?.ContactMetaTitle : "Contact"}</title>
        <meta name="description" content={meta && meta[0]?.ContactMetaDesc ? meta[0]?.ContactMetaDesc : "Contact Description"} />
      </Helmet>
      <div className="contact-banner">
        <div className="container">
          <div className="row">
            <div className="col content text-center">
              <h1>
                <b>
                  Contact Us
                  <br />
                </b>
              </h1>
              <p className="text-white my-2">We would love to hear from you.</p>
              <div className="mt-4">
                <button className="main-btn-tn">
                  <NavLink to="#" className="text-white" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* contact section start  */}
      <div className="main-contact py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 main-form mb-3">
              <div className="title mb-3">
                <h2>
                  <span className="animate">CONTACT US</span>
                  GET IN <span className="text-green"> TOUCH </span> <br /> WITH
                  US
                </h2>
              </div>
              <Form noValidate validated={validated} ref={Contactform} onSubmit={sendEmail}>
                <div className="row mb-3">
                  <Form.Group controlId="formBasicName" className="col">
                    <Form.Control
                      type="text"
                      name="user_name"
                      placeholder="Full Name"
                      required
                      className="shadow-none"
                    />
                    <Form.Control
                      type="text"
                      name="PageNameTracking"
                      hidden
                      value="Contact Page"
                      className="shadow-none"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid name.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail" className="col">
                    <Form.Control
                      type="email"
                      name="user_email"
                      placeholder="Enter Your email"
                      required
                      className="shadow-none"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid email address.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="row mb-3">
                  <Form.Group controlId="formBasicCompanyName" className="col">
                    <Form.Control
                      type="text"
                      name="user_company_name"
                      placeholder="Company Name"
                      required
                      className="shadow-none"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid company name.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formBasicPhone" className="col">
                    <Form.Control
                      type="tel"
                      name="user_phone_number"
                      placeholder="Phone"
                      required
                      className="shadow-none"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid phone number.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <Form.Group controlId="formBasicSubject" className="mb-3">
                  <Form.Control
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    required
                    className="shadow-none"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid subject.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formBasicMessage">
                  <Form.Control
                    as="textarea"
                    name="user_message"
                    placeholder="Type message"
                    required
                    row={10}
                    className="shadow-none"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid message.
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="mt-4 text-center">
                  <button type="submit" className="main-btn-tn">
                    <NavLink to="javascript:void(0);">
                      <span>
                        Submit
                      </span>
                    </NavLink>
                  </button>
                </div>
              </Form>
            </div>
            <div className="col-md-5">
              <div className="imgbx">
                <img
                  src={ContactAnime}
                  className="img-fluid"
                />
              </div>
              <div className="content">
                <h6 className="my-3">Pakistan Office</h6>
                <ul>
                <li>
                <FontAwesomeIcon icon={faPhone} />
                <NavLink to="tel:209-227-2277">+(209) 227 2277 </NavLink>
              </li>
              <li>
                <FontAwesomeIcon icon={faEnvelope} />
                <NavLink to="mailto:sales@outsourcingtopakistan.com">sales@outsourcingtopakistan.com </NavLink>
              </li>
              <li>
                <FontAwesomeIcon icon={faMapMarker} />
                <span> 4 New Hyde Park Franklin Square New York </span>
              </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* contact section end  */}

      {/* map section start  */}
      <div className="main-map mb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-5">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3024.468367872061!2d-73.6756443!3d40.707704799999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c26321b7894d0d%3A0x3c816b2293fec3e6!2s4%20New%20Hyde%20Park%20Rd%2C%20Franklin%20Square%2C%20NY%2011010%2C%20USA!5e0!3m2!1sen!2s!4v1696683140865!5m2!1sen!2s" width="100%"
                height="600"
                allowfullscreen=""
                loading="lazy"></iframe>
            </div>
          </div>
        </div>
      </div>
      {/* map section end  */}

      <ReUsable handleModalShow={handleModalShow} />
    </>
  );
};

export default Contact;
