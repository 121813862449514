import React, { useState, useRef } from 'react';
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";

const MultiStepForm = ({ PageNameTracking }) => {

  const totalSteps = 4;
  const [isRadioSelected, setIsRadioSelected] = useState(false);

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    roles: '',
    employeeCount: '',
    seatsNeeded: '',
    industry: '',
    trackingPage: '',
  });

  const navigate = useNavigate();

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (step < totalSteps) {
      if (validateRadioSelection()) {
        setStep(step + 1);
      } else {
        // Show an error message or take appropriate action to inform the user.
      }
    }
  };

  const handlePrevious = (e) => {
    e.preventDefault();
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const planForm = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Send the form data via email using emailjs
    emailjs
      .sendForm(
        "service_5h2m03q",
        "template_7iq10rg",
        planForm.current, // <-- Use the current property to access the form element
        "1AZnpvAoFrweJXY5R" // corrected the user id here
      )
      .then(
        (result) => {
          toast.info("We will notify you soon");
          navigate("/thank-you");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const validateRadioSelection = () => {
    if (step === 1) {
      return formData.seatsNeeded !== '';
    } else if (step === 2) {
      return formData.employeeCount !== '';
    }
    return true;
  };

  const renderStep = () => {

    return (
      <Form ref={planForm} onSubmit={handleSubmit}>
        {step === 1 && (
          <div>
            <h5 className='fs-6 text-center mb-3 text-white'>How many seats do you need to outsource?</h5>
            <Form.Check
              type="radio"
              name="seatsNeeded"
              id="seats1-10"
              label="1 - 10 Seats"
              value="1-10 Seats"
              checked={formData.seatsNeeded === "1-10 Seats"}
              onChange={(e) => {
                handleChange(e);
                setIsRadioSelected(true);
              }}
              required
              className="text-white"
            />
            <Form.Check
              type="radio"
              name="seatsNeeded"
              id="seats11-20"
              label="11 - 20 Seats"
              value="11-20 Seats"
              checked={formData.seatsNeeded === "11-20 Seats"}
              onChange={(e) => {
                handleChange(e);
                setIsRadioSelected(true);
              }}
              required
              className="text-white"
            />
            <Form.Check
              type="radio"
              name="seatsNeeded"
              id="seats21-30"
              label="21 - 30 Seats"
              value="21-30 Seats"
              checked={formData.seatsNeeded === "21-30 Seats"}
              onChange={(e) => {
                handleChange(e);
                setIsRadioSelected(true);
              }}
              required
              className="text-white"
            />
            <Form.Check
              type="radio"
              name="seatsNeeded"
              id="seats31+"
              label="31+ Seats"
              value="31+ Seats"
              checked={formData.seatsNeeded === "31+ Seats"}
              onChange={(e) => {
                handleChange(e);
                setIsRadioSelected(true);
              }}
              required
              className="text-white mb-3"
            />
            <div className="text-center">
              <Button onClick={handleNext} variant='success'>Next</Button>
            </div>
          </div>
        )}

        {step === 2 && (
          <div>
            <h5 className='fs-6 text-white text-center mb-3'>How many employees do you have?</h5>
            <Form.Check
              type="radio"
              name="employeeCount"
              id="employeeLessThan5"
              label="Less than 5"
              value="Less than 5"
              checked={formData.employeeCount === "Less than 5"}
              onChange={(e) => {
                handleChange(e);
                setIsRadioSelected(true);
              }}
              required
              className="text-white"
            />
            <Form.Check
              type="radio"
              name="employeeCount"
              id="employee6-20"
              label="6 - 20"
              value="6-20"
              checked={formData.employeeCount === "6-20"}
              onChange={(e) => {
                handleChange(e);
                setIsRadioSelected(true);
              }}
              required
              className="text-white"
            />
            <Form.Check
              type="radio"
              name="employeeCount"
              id="employee21-50"
              label="21 - 50"
              value="21-50"
              checked={formData.employeeCount === "21-50"}
              onChange={(e) => {
                handleChange(e);
                setIsRadioSelected(true);
              }}
              required
              className="text-white"
            />
            <Form.Check
              type="radio"
              name="employeeCount"
              id="employee51-250"
              label="51 - 250"
              value="51-250"
              checked={formData.employeeCount === "51-250"}
              onChange={(e) => {
                handleChange(e);
                setIsRadioSelected(true);
              }}
              required
              className="text-white"
            />
            <Form.Check
              type="radio"
              name="employeeCount"
              id="employee250+"
              label="250+"
              value="250+"
              checked={formData.employeeCount === "250+"}
              onChange={(e) => {
                handleChange(e);
                setIsRadioSelected(true);
              }}
              required
              className="text-white mb-3"
            />
            <div className="d-flex justify-content-between">
              <Button onClick={handlePrevious} variant='danger' className='text-white'>Previous</Button>
              <Button onClick={handleNext} variant='success'>Next</Button>
            </div>
          </div>
        )}

        {step === 3 && (
          <div>
            <div className="mb-3">
              <Form.Label htmlFor="industry" className="form-label text-white"><strong>What industry is your business in?*</strong></Form.Label>
              <Form.Select
                id="industry"
                name="industry"
                value={formData.industry}
                onChange={handleChange}
                required
              >
                <option Selected>Select Industry</option>
                <option value="Automobile">Automobile</option>
                <option value="Aviation">Aviation</option>
                <option value="BFSI">BFSI</option>
                <option value="Broadband/DTH">Broadband/DTH</option>
                <option value="D2C">D2C</option>
                <option value="E Com">E Com</option>
                <option value="Ed-tech">Ed-tech</option>
                <option value="FMCD">FMCD</option>
                <option value="Foodtech">Foodtech</option>
                <option value="Healthcare">Healthcare</option>
                <option value="Internet">Internet</option>
                <option value="Logistics">Logistics</option>
                <option value="Mobility">Mobility</option>
                <option value="Real Estate">Real Estate</option>
                <option value="Retail">Retail</option>
                <option value="Travel&Tourism">Travel &amp; Tourism</option>
                <option value="Others">Others</option>
              </Form.Select>
            </div>
            <div className="mb-3">
              <Form.Label htmlFor="roles" className="form-label text-white"><strong>What roles are you looking to outsource?*</strong></Form.Label>
              <Form.Control
                type="text"
                placeholder="roles"
                name="roles"
                required
                value={formData.roles}
                class="text-white"
                onChange={handleChange}
              />
            </div>

            <div className="d-flex justify-content-between">
              <Button onClick={handlePrevious} variant='danger' className='text-white'>Previous</Button>
              <Button onClick={handleNext} variant='success'>Next</Button>
            </div>
          </div>
        )}

        {step === 4 && (
          <div>
            <div className="mb-3">
              <Form.Label htmlFor="roles" className="form-label text-white"><strong>What roles are you looking to outsource?*</strong></Form.Label>
              <Form.Control
                type="text"
                placeholder="Full Name"
                name="full_name"
                required
                value={formData.full_name}
                class="text-white"
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <Form.Control
                type="email"
                placeholder="Business email"
                name="business_email"
                required
                value={formData.business_email}
                class="text-white"
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <Form.Control
                type="text"
                placeholder="Phone Number"
                name="phone_number"
                required
                value={formData.phone_number}
                class="text-white"
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <Form.Control
                type="text"
                placeholder="Company Name"
                name="company_name"
                required
                value={formData.company_name}
                class="text-white"
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <Form.Control
                type="text"
                placeholder="trackingPage"
                name="trackingPage"
                hidden
                value={PageNameTracking}
                className="text-white"
                onChange={handleChange}
              />
            </div>
            <div className="d-none">

              <Form.Check
                type="radio"
                name="employeeCount"
                id="employeeLessThan5"
                label="Less than 5"
                value="Less than 5"
                checked={formData.employeeCount === "Less than 5"}
                onChange={handleChange}
                required
                className="text-white"
              />
              <Form.Check
                type="radio"
                name="employeeCount"
                id="employee6-20"
                label="6 - 20"
                value="6-20"
                checked={formData.employeeCount === "6-20"}
                onChange={handleChange}
                required
                className="text-white"
              />
              <Form.Check
                type="radio"
                name="employeeCount"
                id="employee21-50"
                label="21 - 50"
                value="21-50"
                checked={formData.employeeCount === "21-50"}
                onChange={handleChange}
                required
                className="text-white"
              />
              <Form.Check
                type="radio"
                name="employeeCount"
                id="employee51-250"
                label="51 - 250"
                value="51-250"
                checked={formData.employeeCount === "51-250"}
                onChange={handleChange}
                required
                className="text-white"
              />
              <Form.Check
                type="radio"
                name="employeeCount"
                id="employee250+"
                label="250+"
                value="250+"
                checked={formData.employeeCount === "250+"}
                onChange={handleChange}
                required
                className="text-white mb-3"
              />

              <Form.Check
                type="radio"
                name="seatsNeeded"
                id="seats1-10"
                label="1 - 10 Seats"
                value="1-10 Seats"
                checked={formData.seatsNeeded === "1-10 Seats"}
                onChange={handleChange}
                required
                className="text-white"
              />
              <Form.Check
                type="radio"
                name="seatsNeeded"
                id="seats11-20"
                label="11 - 20 Seats"
                value="11-20 Seats"
                checked={formData.seatsNeeded === "11-20 Seats"}
                onChange={handleChange}
                required
                className="text-white"
              />
              <Form.Check
                type="radio"
                name="seatsNeeded"
                id="seats21-30"
                label="21 - 30 Seats"
                value="21-30 Seats"
                checked={formData.seatsNeeded === "21-30 Seats"}
                onChange={handleChange}
                required
                className="text-white"
              />
              <Form.Check
                type="radio"
                name="seatsNeeded"
                id="seats31+"
                label="31+ Seats"
                value="31+ Seats"
                checked={formData.seatsNeeded === "31+ Seats"}
                onChange={handleChange}
                required
                className="text-white mb-3"
              />

            </div>
            <div className="d-none">
            <div className="mb-3">
              <Form.Label htmlFor="industry" className="form-label text-white"><strong>What industry is your business in?*</strong></Form.Label>
              <Form.Select
                id="industry"
                name="industry"
                value={formData.industry}
                onChange={handleChange}
                required
              >
                <option Selected>Select Industry</option>
                <option value="Automobile">Automobile</option>
                <option value="Aviation">Aviation</option>
                <option value="BFSI">BFSI</option>
                <option value="Broadband/DTH">Broadband/DTH</option>
                <option value="D2C">D2C</option>
                <option value="E Com">E Com</option>
                <option value="Ed-tech">Ed-tech</option>
                <option value="FMCD">FMCD</option>
                <option value="Foodtech">Foodtech</option>
                <option value="Healthcare">Healthcare</option>
                <option value="Internet">Internet</option>
                <option value="Logistics">Logistics</option>
                <option value="Mobility">Mobility</option>
                <option value="Real Estate">Real Estate</option>
                <option value="Retail">Retail</option>
                <option value="Travel&Tourism">Travel &amp; Tourism</option>
                <option value="Others">Others</option>
              </Form.Select>
            </div>
            <div className="mb-3">
              <Form.Label htmlFor="roles" className="form-label text-white"><strong>What roles are you looking to outsource?*</strong></Form.Label>
              <Form.Control
                type="text"
                placeholder="roles"
                name="roles"
                required
                value={formData.roles}
                class="text-white"
                onChange={handleChange}
              />
            </div>
            </div>
            <div className="d-flex justify-content-between">
              <Button onClick={handlePrevious} variant='danger' className='text-white'>Previous</Button>
              <Button type="submit" variant='success'>Submit</Button>
            </div>
          </div>
        )}
      </Form>
    );


  };




  return (
    <>
      {renderStep()}
    </>
  );
};

export default MultiStepForm;
