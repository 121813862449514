import { useState, useEffect, } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../Firebase";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReUsable from "../components/ReUsable"
import Portfoliotabs from "../components/PortfolioTabs";
import { Row, Col, Button, Form } from "react-bootstrap";


const Portfolio = ({ handleModalShow, sendForm, sendEmail }) => {

  const [meta, setMeta] = useState([]);

  const fetchMeta = async () => {
    const BlogColl = collection(db, "metaData");
    await getDocs(BlogColl).then((querySnapshot) => {
      const newData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      setMeta(newData);
    })
  }
  useEffect(() => {
    fetchMeta();
  }, [])

  return (
    <>
      <Helmet>
        <title>{meta && meta[0]?.PortfolioMetaTitle ? meta[0]?.PortfolioMetaTitle : "Portfolio"}</title>
        <meta name="description" content={meta && meta[0]?.PortfolioMetaDesc ? meta[0]?.PortfolioMetaDesc : "Portfolio Description"} />
      </Helmet>
      <div className="portfolio-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col content text-lg-start text-center">
              <h1>
                <b>
                  Our Portfolio
                  <br />
                </b>
              </h1>
              <p className="text-white my-4">Helping You Focus On Bringing In New Business, Developing Your Own Brand And Acquiring New Technologies.</p>
            </div>
            <div className="col-md-5 d-lg-block d-none">
              <div className="banner__form card border-0">
                <div className="card-body">
                  <h4 className="mb-4 text-white text-center">Let's Get <br /> Started</h4>
                  <Form ref={sendForm} onSubmit={sendEmail}>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Control
                            type="text"
                            name="user_name"
                            placeholder="Full Name"
                            className="shadow-none"
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          name="PageNameTracking"
                          hidden
                          value="Portfolio Page"
                          className="shadow-none"
                        />
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Control
                            type="email"
                            name="user_email"
                            placeholder="Enter Your email"
                            className="shadow-none"
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Control
                            type="tel"
                            name="user_phone_number"
                            placeholder="phone number"
                            className="shadow-none"
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Control
                            type="text"
                            name="user_company_name"
                            placeholder="company name"
                            className="shadow-none"
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Control type="text" name="subject" placeholder="Subject"
                        className="shadow-none"
                        required />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Control
                        as="textarea"
                        name="user_message"
                        rows={5}
                        className="shadow-none"
                        placeholder="type Message"
                        required
                      />
                    </Form.Group>
                    <div className="text-center">
                      <Button type="submit" className="popup-submit">
                        <span> Submit</span>
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Portfoliotabs />


      <ReUsable handleModalShow={handleModalShow} />
    </>
  );
};

export default Portfolio;
