import { auth } from "./Firebase";
import { useState, useEffect } from "react";
import "./App.css";
import Layouts from "./Layout/Layouts";
import LoadingScreen from "./components/Loading";
import { signOut } from "firebase/auth";
import { toast } from "react-toastify";
import { useLocation } from 'react-router-dom';

const App = () => {

  const [user, setUser] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });
  }, []);

  const handleLogout = () => {
    signOut(auth).then(() => {
      setUser(null);
      toast.success("Logout, successfully");
    });
  };

  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    document.querySelector('link[rel="canonical"]').setAttribute('href', window.location.href);
    window.scrollTo(0, 0);
  }, [location]);

  return <>
    {isLoading ? (
      <LoadingScreen />
    ) : (
      <Layouts handleLogout={handleLogout} user={user} />
    )}
  </>
}
export default App;
