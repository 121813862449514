import { collection, addDoc, getDocs, doc, updateDoc, deleteDoc, orderBy, query, serverTimestamp } from "firebase/firestore";
import { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faSearch, faEye } from "@fortawesome/fontawesome-free-solid";
import { NavLink } from "react-router-dom";
import { db } from '../../Firebase';
import { toast } from "react-toastify";
import ExportExcel from "./BlogImportExportExcel";
import { Helmet } from "react-helmet";
import { excerpt } from '../../components/Excerpt';

const BlogView = () => {
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [blogsPerPage, setBlogsPerPage] = useState(20);

  const fetchPost = async () => {
    const BlogColl = collection(db, "blogs");
    const BlogQuery = query(BlogColl, orderBy('timestamp', 'desc'));
    await getDocs(BlogQuery).then((querySnapshot) => {
      const newData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      setBlogs(newData);
    })
  }

  useEffect(() => {
    fetchPost();
  }, []);

  // delete data from firestore 
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure wanted to delete that blog?")) {
      try {
        await deleteDoc(doc(db, "blogs", id));
        fetchPost();
        toast.success("Blog deleted successfully");
      } catch (err) {
        toast.error(err);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [blogs]);

  const [search, setSearch] = useState("");
  const formRef = useRef(null);

  const searchBlogs = (e) => {
    e.preventDefault();
    setBlogs(blogs.filter((blogs) =>
      blogs.title.toLowerCase().includes(search.toLowerCase())
    ));
  }

  const handleReset = () => {
    setSearch("");
    formRef.current.reset();
    fetchPost();
  }

  // pagination
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(blogs.length / blogsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Display the result on the page
  return (
    <>
      <Helmet>
        <title>Blogs CRUD</title>
        <meta name="description" content="Blogs crud description" />
      </Helmet>
      <div className="view-table my-5" >
        <div className="container">
          <div className="card">
            <div className="card-body">
              <div className="trigger-btn text-end mb-3">
                <div className="row">
                  <ExportExcel />
                  <div className="col-md-6">
                    <button className="btn btn-primary d-lg-inline d-none me-2">
                      <NavLink className="text-white" to="/blog-create">
                        Add Blog
                      </NavLink>
                    </button>
                    <button className="btn btn-primary d-lg-none d-block w-100 me-2">
                      <NavLink className="text-white" to="/blog-create">
                        Add Blog
                      </NavLink>
                    </button>
                  </div>
                </div>
                <form className="search mt-3" onSubmit={(e) => searchBlogs(e)} ref={formRef}>
                  <div className="row">
                    <div className="col">
                      <input
                        type="text"
                        placeholder="Search Blog"
                        className="form-control shadow-none"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                    <div className="col-auto">
                      <button type="submit" className="btn btn-warning text-white me-2">
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                      <button type="button" className="btn btn-dark rounded" onClick={handleReset}>
                        Reset
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="table-responsive text-nowrap">
                <table className="table">
                  <thead>
                    <tr className="table-secondary">
                      <th>#</th>
                      <th>Title</th>
                      <th>Slug</th>
                      <th>Image</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentBlogs.map((blog, index) => (
                      <tr key={index}>
                        <td className="align-middle">{indexOfFirstBlog + index + 1}</td>
                        <td className="align-middle">{excerpt(blog.title, 50)}</td>
                        <td className="align-middle">{excerpt(blog.slug, 30)}</td>
                        <td className="align-middle"><img src={blog.imgUrl} className="img-fluid" width="100" height="50" /></td>
                        <td className="align-middle">
                          <button className="btn btn-info me-2">
                            <NavLink className="text-white" to={`/blog-update/${blog.id}`}>
                              <FontAwesomeIcon icon={faEdit} />
                            </NavLink>
                          </button>
                          <button className="btn btn-danger me-2" onClick={() => handleDelete(blog.id)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                          <NavLink to={`/blog/${blog.slug}`} target="_blank" className="btn btn-warning" >
                            <FontAwesomeIcon icon={faEye} />
                          </NavLink>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="d-flex justify-content-center">

                  <ul className="pagination">
                    <li className="page-item">
                      <button
                        className="page-link shadow-none"
                        disabled={currentPage <= 1}
                        onClick={() => setCurrentPage(currentPage - 1)}
                      >
                        Previous
                      </button>
                    </li>
                    {pageNumbers.map((number) => (
                      <li key={number} className="page-item">
                        <button
                          className={`page-link shadow-none ${currentPage === number ? "active" : ""}`}
                          onClick={() => paginate(number)}
                        >
                          {number}
                        </button>
                      </li>
                    ))}
                    <li className={`page-item ${currentPage === pageNumbers[pageNumbers.length - 1] ? 'disabled' : ''
                      }`}>
                      <button
                        className="page-link shadow-none"
                        disabled={currentBlogs.length < blogsPerPage}
                        onClick={() => setCurrentPage(currentPage + 1)}
                      >
                        Next
                      </button>
                    </li>
                  </ul>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>);
};

export default BlogView;