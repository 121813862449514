import { initializeApp }  from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAxHSlZFx2oPOz2Sv8Kx0gkLepQmTwT18U",
  authDomain: "outsourcingtopakistan.firebaseapp.com",
  projectId: "outsourcingtopakistan",
  storageBucket: "outsourcingtopakistan.appspot.com",
  messagingSenderId: "461063056691",
  appId: "1:461063056691:web:b35ed8a59fedf91531a246",
  measurementId: "G-Z2FPHHDYSK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };

