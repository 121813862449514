import { useEffect, useState } from "react";
import { db } from '../Firebase';
import { collection, getDocs } from "firebase/firestore";
import { NavLink } from "react-router-dom";
import { Carousel, Modal, Col, Form, Row, Button } from "react-bootstrap";
import Helmet from "react-helmet";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Pagination, Autoplay } from "swiper";
import ReUsable from "../components/ReUsable"
import LaptopImg from "../assets/__images/laptop.png"
import lowOperatingImg from "../assets/images/lowOperatingImg.webp"
import salesVsProduction from "../assets/images/salesVsProduction.webp"
import TabletImg from "../assets/images/tablet.webp"
import SignUp from "../assets/images/sign-up.webp"
import CreatePro from "../assets/images/create-pro.webp"
import GetIt from "../assets/images/get-it.webp"
import Accordion from 'react-bootstrap/Accordion';
import brandingIcon from "../assets/images/branding-icon.webp"
import webDevelopment from "../assets/images/web-development-icon.webp"
import socialMedia from "../assets/images/social-media-icon.webp"
import trafficIcon from "../assets/images/lead-gen.webp"
import appDevelopment from "../assets/images/app-dev-icon.webp"
import seoIcon from "../assets/images/seo-icon.webp"
import vdoDevelopIcon from "../assets/images/video-develop-icon.webp"
import cctvMonitoring from "../assets/images/cctv-icon.webp"
import contentWriting from "../assets/images/content-writting-icon.webp"
import MultiStepForm from "../components/MultiStepForm";

const Home = ({ handleModalShow, sendForm, sendEmail }) => {

  const [show, setVideoModalShow] = useState(false);
  const handleVideoModalClose = () => setVideoModalShow(false);
  const handleVideoModalShow = () => setVideoModalShow(true);

  const [meta, setMeta] = useState([]);

  const fetchMeta = async () => {
    const BlogColl = collection(db, "metaData");
    await getDocs(BlogColl).then((querySnapshot) => {
      const newData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      setMeta(newData);
    })
  }

  useEffect(() => {
    fetchMeta();
  }, [])

  return (
    <>
      <Helmet>
        <title>{meta && meta[0]?.HomeMetaTitle ? meta[0]?.HomeMetaTitle : "Home"}</title>
        <meta name="description" content={meta && meta[0]?.HomeMetaDesc ? meta[0]?.HomeMetaDesc : "Home Description"} />
      </Helmet>
      <div className="home-banner d-flex justify-content-center align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col content text-lg-start text-center">
              <h1>
                <b>
                  Revolutionizing Back-Office <span className="text-green">Support Services</span>
                </b>
              </h1>
              <p className="text-white pt-3">Paving the Way for Effortless Operations</p>
            </div>
            <div className="col-md-5 d-lg-block d-none">
              <div className="banner__form card border-0">
                <div className="card-body">
                  <h4 className="mb-4 text-white text-center">Let's Get <br /> Started</h4>
                  <Form ref={sendForm} onSubmit={sendEmail}>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Control
                            type="text"
                            name="user_name"
                            placeholder="Full Name"
                            className="shadow-none"
                            required
                          />
                          <Form.Control
                            type="text"
                            name="PageNameTracking"
                            hidden
                            value="Home Page"
                            className="shadow-none"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Control
                            type="email"
                            name="user_email"
                            placeholder="Enter Your email"
                            className="shadow-none"
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Control
                            type="tel"
                            name="user_phone_number"
                            placeholder="phone number"
                            className="shadow-none"
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Control
                            type="text"
                            name="user_company_name"
                            placeholder="company name"
                            className="shadow-none"
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Control type="text" name="subject" placeholder="Subject"
                        className="shadow-none"
                        required />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Control
                        as="textarea"
                        name="user_message"
                        rows={5}
                        className="shadow-none"
                        placeholder="type Message"
                        required
                      />
                    </Form.Group>
                    <div className="text-center">
                      <Button type="submit" className="popup-submit">
                        <span> Submit</span>
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* section 2 start  */}
      <div className="why-outsource py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
              <h2 className="pt-4">
                <span className="back-bigtext">Why?</span>
                <span>Outsourcing to</span> <br />
                <span className="text-green">Pakistan</span>
              </h2>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 col-12 float-left">
              <p>
                In the ever-changing environment of today&#39;s businesses, remaining stagnant is no longer viable.
                The ability to embrace change, foster innovation, and undergo continuous transformation isn&#39;t
                just a preference – it&#39;s an imperative. Do you find yourself ensnared by the constant demands of
                evolving client requirements? Are escalating operational expenses and a scarcity of talent
                constraining your expansion?
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* section 2 end  */}

      {/* one top soulution start  */}
      <div className="one-top-solution pt-5 ">
        <div className="container">
          <Carousel controls={false} indicators={true}>
            <Carousel.Item interval={1000}>
              <div className="row align-items-center mb-5">
                <div className="col-auto imgbx">
                  <img src={lowOperatingImg} />
                </div>
                <div className="col content pb-4">
                  <h3 className="text-white">
                    Low Operating
                    <span className="text-green d-block">Cost</span>
                  </h3>
                  <p className="pt-3 text-white">
                    We&#39;re not your typical outsourcing company; think of us as the driving force behind your
                    success. With 24 years of experience in 167 countries, we&#39;ve helped many businesses
                    succeed globally. Our outsourcing services are more than just assistance; they&#39;re like
                    building bridges to make your operations work really well.
                  </p>
                  <div className="capsule-normal-btn mt-3">
                    <NavLink to="#" onClick={handleModalShow}>Outsource Now</NavLink>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={1000}>
              <div className="row align-items-center mb-5">
                <div className="col-auto imgbx">
                  <img src={salesVsProduction} />
                </div>
                <div className="col content pb-4">
                  <h3 className="text-white">
                    Sales vs
                    <span className="text-green d-block">Production</span>
                  </h3>
                  <p className="pt-3 text-white">
                    We understand the challenges you face, like finding the right people and keeping costs
                    down. Our approach doesn&#39;t just save you money; it also focuses on delivering quality.
                  </p>
                  <div className="capsule-normal-btn mt-3">
                    <NavLink to="#" onClick={handleModalShow}>Outsource Now</NavLink>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={1000}>
              <div className="row align-items-center mb-5">
                <div className="col-auto imgbx">
                  <img src={TabletImg} />
                </div>
                <div className="col content pb-4">
                  <h3 className="text-white">
                    One stop
                    <span className="text-green d-block">Solution</span>
                  </h3>
                  <p className="pt-3 text-white">
                    When it comes to your data, we take security seriously and make sure we follow all the
                    rules. We treat your data with the same care and honesty as we do with our own
                    processes.
                  </p>
                  <div className="capsule-normal-btn mt-3">
                    <NavLink to="#" onClick={handleModalShow}>Outsource Now</NavLink>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
      {/* one top soulution end  */}

      {/* how works start  */}
      <div className="how-works py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-4 col-sm-12 col-12 float-left heading">
              <h4>How Its Work?</h4>
              <p className="my-3">
                With our advanced technology and skilled team, we adapt to your needs, making
                challenges into opportunities. Our staff augmentation services aren&#39;t just about filling
                gaps; they&#39;re about creating new possibilities.
              </p>
            </div>
            <div className="col-lg-6 col-md-8 col-sm-12 col-12 float-left">
              {/* video --  */}
              <div className="img-in-frame">
                <NavLink onClick={handleVideoModalShow} to="#">
                  <img
                    className="img-fluid img2"
                    src={LaptopImg}
                  />
                </NavLink>
              </div>
              {/* video --  */}
            </div>
          </div>

          {/* 3 step process start*/}
          <div className="row steps-imgs pt-5 text-center">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <h3 className="mb-3">3 Step Process</h3>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12 step-img mb-3">
              <div className="main-step-img">
                <img
                  className="img-fluid"
                  src={SignUp}
                />
              </div>
              <div className="main-step-cont">
                <h6>Sign Up</h6>
                <p className="fs-6 pt-3">Register your free account on OIP Client Portal.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12 step-img mb-3">
              <div className="main-step-img">
                <img
                  className="img-fluid"
                  src={CreatePro}
                />
              </div>
              <div className="main-step-cont">
                <h6>Create Project</h6>
                <p className="fs-6 pt-3">Provide brief and basic information of the Project.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12 step-img mb-3">
              <div className="main-step-img">
                <img
                  className="img-fluid"
                  src={GetIt}
                />
              </div>
              <div className="main-step-cont">
                <h6>Get It Done</h6>
                <p className="fs-6 pt-3">We will deliver your work in shortest period of time.</p>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-5">
              <button className="main-btn-tn">
                <NavLink to="#" onClick={handleModalShow}>
                  <span>Get Started</span>
                </NavLink>
              </button>
            </div>
          </div>
          {/* 3 step process end*/}
        </div>
      </div>
      {/* how works end  */}

      {/* our expertise section start  */}
      <div className="our-expertise py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-5 text-center">
              <h5 className="text-white">
                Our Areas <br />
                <span className="text-green">Of Expertise</span>
              </h5>
            </div>

            <div className="col-md-4 mb-3">
              <div className="areaBg1 bg-white">
                <h6>Design &amp; Development</h6>
                <ul className="pt-3">
                  <li>Logo Design</li>
                  <li>Marketing Design</li>
                  <li>Video Animation</li>
                  <li>Web Development</li>
                  <li>App Development</li>
                  <li>Real-time Visualization</li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="areaBg1 box2 bg-white">
                <h6>Digital Marketing</h6>
                <ul className="pt-3">
                  <li>Search Engine Optimization</li>
                  <li>Social Media Marketing</li>
                  <li>Lead Generation</li>
                  <li>PPC Campaign</li>
                  <li>GDN Advertising</li>
                  <li>Amazon Marketing</li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="areaBg1 box3 bg-white">
                <h6>Business Solution</h6>
                <ul className="pt-3">
                  <li>Business Process Outsourcing</li>
                  <li>Resource Outstaffing</li>
                  <li>CCTV Monitoring</li>
                  <li>Server Management</li>
                  <li>Cyber Security</li>
                  <li>CRM & ERP Systems</li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 text-center mt-5">
              <button className="main-btn-tn">
                <NavLink to="#" className="text-white" onClick={handleModalShow}>
                  <span>Explore More</span>
                </NavLink>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* our expertise section end  */}

      {/* plans section start  */}
      <div className="plans py-5">
        <div className="container">
          <div className="title">
            <h2>
              Outsourcing <br />
              <span className="text-green">Plan</span>
            </h2>
            <p className="py-2">
              Our specialized Business Process Outsourcing solutions are made to work smoothly
              with your company, taking care of important tasks. We understand how crucial these
              tasks are for your success, so we handle them, allowing your team to focus on your
              main business goals. Benefit from improved efficiency, lower costs, and top-notch
              support designed for the specific needs of businesses in the US, UK, and Europe.
            </p>
          </div>

          {/* <Swiper className="pt-3"
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={20}
            slidesPerView={3}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            navigation
            autoplay={{ delay: 2000 }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            <SwiperSlide>
              <div className="plan-box m-2">
                <h6>Agency Startup</h6>
                <p className="py-3">We help you close your sales!</p>
                <ul>
                  <li>Develop Agency</li>
                  <li>Lead Generation</li>
                  <li>Lead Commitment</li>
                  <li>Operational Support</li>
                </ul>
                <div className="capsule-normal-btn pt-2">
                  <NavLink to="#" onClick={handleModalShow}>Read More </NavLink>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="plan-box m-2">
                <h6>White Labelling</h6>
                <p className="py-3">We help you close your sales!</p>
                <ul>
                  <li>Web &amp; App Portal</li>
                  <li>Confidentiality</li>
                  <li>Dedicated Support</li>
                  <li>Bundles Available</li>
                </ul>
                <div className="capsule-normal-btn pt-2">
                  <NavLink to="#" onClick={handleModalShow}>Read More </NavLink>
                </div>
              </div>
            </SwiperSlide>
          </Swiper> */}

          <div>
            <div className="row pt-4 justify-content-center">
              <div className="col-md-6">
                <div className="card bg_dark">
                  <div className="card-header bg-black border-0">
                    <h3 className='text-white text-center'>Contact us</h3>
                  </div>
                  <div className="card-body">
                    <MultiStepForm PageNameTracking="Home Page" />
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
      {/* plans section end  */}


      <div className="project py-5">
        <div className="container">
          <h3 className="text-center">BACK OFFICE OUTSOURCING SERVICES</h3>
          <div className="row pt-4">
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <NavLink to="" className="pro-box align-items-center row">
                <div className="imgbx col-auto">
                  <img
                    className="img-fluid"
                    src={brandingIcon}
                  />
                </div>
                <div className="name col">
                  <h4>Creative Designing</h4>
                  <span className="fs-6 text-white">Services</span>
                </div>
              </NavLink>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <NavLink to="" className="pro-box align-items-center row">
                <div className="imgbx col-auto">
                  <img
                    className="img-fluid"
                    src={webDevelopment}
                  />
                </div>
                <div className="name col">
                  <h4>Web Development</h4>
                  <span className="fs-6 text-white">Services</span>
                </div>
              </NavLink>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <NavLink to="" className="pro-box align-items-center row">
                <div className="imgbx col-auto">
                  <img
                    className="img-fluid"
                    src={socialMedia}
                  />
                </div>
                <div className="name col">
                  <h4>Social Media Marketing</h4>
                  <span className="fs-6 text-white">Services</span>
                </div>
              </NavLink>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <NavLink to="" className="pro-box align-items-center row">
                <div className="imgbx col-auto">
                  <img
                    className="img-fluid"
                    src={trafficIcon}
                  />
                </div>
                <div className="name col">
                  <h4>Lead Generation</h4>
                  <span className="fs-6 text-white">Services</span>
                </div>
              </NavLink>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <NavLink to="" className="pro-box align-items-center row">
                <div className="imgbx col-auto">
                  <img
                    className="img-fluid"
                    src={appDevelopment}
                  />
                </div>
                <div className="name col">
                  <h4>App Development</h4>
                  <span className="fs-6 text-white">Services</span>
                </div>
              </NavLink>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <NavLink to="" className="pro-box align-items-center row">
                <div className="imgbx col-auto">
                  <img
                    className="img-fluid"
                    src={seoIcon}
                  />
                </div>
                <div className="name col">
                  <h4>Search Engine Optimization</h4>
                  <span className="fs-6 text-white">Services</span>
                </div>
              </NavLink>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <NavLink to="" className="pro-box align-items-center row">
                <div className="imgbx col-auto">
                  <img
                    className="img-fluid"
                    src={vdoDevelopIcon}
                  />
                </div>
                <div className="name col">
                  <h4>Video Development</h4>
                  <span className="fs-6 text-white">Services</span>
                </div>
              </NavLink>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <NavLink to="" className="pro-box align-items-center row">
                <div className="imgbx col-auto">
                  <img
                    className="img-fluid"
                    src={cctvMonitoring}
                  />
                </div>
                <div className="name col">
                  <h4>CCTV Monitoring</h4>
                  <span className="fs-6 text-white">Services</span>
                </div>
              </NavLink>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <NavLink to="" className="pro-box align-items-center row">
                <div className="imgbx col-auto">
                  <img
                    className="img-fluid"
                    src={contentWriting}
                  />
                </div>
                <div className="name col">
                  <h4>Content Writing</h4>
                  <span className="fs-6 text-white">Services</span>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="faqs py-5">
        <div className="container">
          <h3 className="text-center">Frequently Asked Questions (FAQs)</h3>
          <Accordion defaultActiveKey="0" className="pt-4">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="p-0 shadow-none bg-light">What are back office support services?</Accordion.Header>
              <Accordion.Body>
                Back office support services encompass a range of crucial, yet often overlooked, tasks
                that form the backbone of a well-functioning business. These tasks include
                administrative duties, data management, financial processes, and more.
                <br />
                At O2P, we specialize in providing tailored solutions to streamline and manage these
                essential behind-the-scenes operations. By entrusting us with your back office needs,
                your organization gains the freedom to direct its energies towards growth and
                innovation, knowing that the foundational aspects are in expert hands. Our goal is to
                empower your business by ensuring that these vital functions are executed efficiently,
                allowing you to thrive and prosper.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="p-0 shadow-none bg-light">What are the benefits of back-office outsourcing?</Accordion.Header>
              <Accordion.Body>
                Delving into back-office outsourcing yields a spectrum of advantages, ranging from enhanced
                efficiency and substantial cost savings to newfound flexibility. For businesses grappling with
                these pressing concerns, a strategic partnership with a trusted provider such as O2I can serve
                as a pivotal catalyst, propelling you towards the attainment of your operational objectives.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header className="p-0 shadow-none bg-light">Is Outsourcing Back Office Services the Cost-Efficient Choice for Your Company?</Accordion.Header>
              <Accordion.Body>
                In the quest for cost-effective solutions, your priority becomes crystal clear. The decision to
                outsource back office services to O2P presents a dynamic and effective avenue for managing
                expenses without compromising quality. In this way, it directly addresses and alleviates your
                financial concerns while providing flexibility in your operations.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header className="p-0 shadow-none bg-light">How can I choose the right back office support provider for my B2B business?</Accordion.Header>
              <Accordion.Body>
                Choosing the right provider is a pivotal decision that can significantly impact your B2B business.
                O2P emerges as a distinguished and reliable partner, renowned for delivering customized
                solutions to businesses like yours. Our expert guidance and extensive experience stand as
                valuable assets to guide you through this crucial decision-making process, empowering
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header className="p-0 shadow-none bg-light">How can I choose the right back office support provider for my B2B business?</Accordion.Header>
              <Accordion.Body>
                Choosing the right provider is a pivotal decision that can significantly impact your B2B business.
                O2P emerges as a distinguished and reliable partner, renowned for delivering customized
                solutions to businesses like yours. Our expert guidance and extensive experience stand as
                valuable assets to guide you through this crucial decision-making process, empowering you to
                make a choice that aligns seamlessly with your unique business needs.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header className="p-0 shadow-none bg-light">Do you provide back office services which can be customized based on my business needs?</Accordion.Header>
              <Accordion.Body>
                In the realm of business, one size certainly does not fit all. Your distinct business needs call for
                solutions that are finely tuned to your specifications. At O2I, we take pride in offering fully
                customizable back office services. This commitment to adaptability ensures that our services
                align seamlessly with your unique requirements, demonstrating our unwavering dedication to
                catering to your precise needs.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header className="p-0 shadow-none bg-light">How can I get started with back office support services for my company?</Accordion.Header>
              <Accordion.Body>
                Embarking on the path of back office support can indeed appear as a complex undertaking.
                However, O2P simplifies this process for you. All you need to do is initiate contact with us, and
                our dedicated team will take you by the hand, guiding you through each step. Our mission is to
                make this transition as smooth and hassle-free as possible, mirroring the successful journeys
                we&#39;ve facilitated for countless businesses across the globe. Your journey towards optimized
                operations begins with a single step - reaching out to us.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>


      <ReUsable handleModalShow={handleModalShow} />

      {/* modal start for email  */}
      <Modal show={show} onHide={handleVideoModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <video width="100%;" controls autoPlay={true}>
            <source src="https://outsourceinpakistan.com/assets/images/Portfolio/video/outsource-video2.mp4" type="video/mp4" />
          </video>
        </Modal.Body>
      </Modal>
      {/* modal end for email  */}

    </>
  );
};

export default Home;
